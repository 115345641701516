
export const MODE = process.env.REACT_APP_MODE || 'TESTNET';
export const BSC_EXPLORER = {
    MAINNET: 'https://bscscan.com',
    TESTNET: 'https://testnet.bscscan.com'
}

export const BSC_NODE_URL = {
    MAINNET: [
        "https://bsc-dataseed1.ninicoin.io/",
        "https://bsc-dataseed.binance.org/",
        "https://bsc-dataseed1.defibit.io/",
    ],
    TESTNET: ['https://data-seed-prebsc-1-s2.binance.org:8545/']
}

export const STAKING_CONTRACT_ADDRESS = {
    MAINNET: '0x8e3Cfca4995D2EC53E1001707Cd6F3D58A32b334',
    TESTNET: '0xe0b0C16038845BEd3fCf70304D3e167Df81ce225'
}
export const BSC_CPO_ADDRESS = {
    MAINNET: '0xea395DFaFEd39924988b475f2Ca7f4C72655203A',
    TESTNET: '0xe0d24e7695fd9dcc57a097388811fbaeece68ce7'
}

// export const chainId