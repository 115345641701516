import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { getStakingContractInfo } from "../utils/bscHelpers";
import { helpers } from "../utils";
import RightBar from "./RightBar";
import { ACTION_CONST } from "../constants";
import Footer from "../components/Footer";
import { changeTab } from "../../redux/action/app";

const StakingPortalPage = ({ selectedTab, children }) => {
    const dispatch = useDispatch();
    const [stakerCount, setStakerCount] = useState(0);
    const [totalStakedAmount, setTotalStakedAmount] = useState(0);
    const [apyPercentage, setApyPercentage] = useState(0);
    const stakingInfo = useSelector((state) => get(state, "wallet.stakingInfo", {}));
    const submitOK = useSelector((state) =>
        get(state, "utils.blocking", false)
    );

    useEffect(() => {
        dispatch(changeTab(1));
    }, [])

    useEffect(() => {
        try {
            getStakingContractInfo().then(data => {
                setStakerCount(data.stakerCount);
                setTotalStakedAmount(data.totalStakedAmount);
                setApyPercentage(data.apyPercentage);
                dispatch({ type: ACTION_CONST.GET_STAKING_INFO, data: data });
            })
        } catch (error) {
            console.log(error);
        }

        //setup interval
        const job = setInterval(() => {
            getStakingContractInfo().then(data => {

                setStakerCount(data.stakerCount);
                setTotalStakedAmount(data.totalStakedAmount);
                setApyPercentage(data.apyPercentage);
                dispatch({ type: ACTION_CONST.GET_STAKING_INFO, data: data });
            })
        }, 10 * 1000);
        dispatch({ type: ACTION_CONST.SET_JOB_GET_STAKING_INFO, job });
    }, [dispatch, submitOK]);

    return (
        <>
            <div className="container text-center mt-5 mb-2">
                <img src="/images/logo.svg" style={{ maxWidth: '100%' }} />
                <h1 className="welcome-text">
                    Welcome to the Cryptopolis - Staking!
                </h1>
            </div>
            {
                stakingInfo["isStakingPaused"] &&
                <div className="container mt-4 text-center d-md-block d-none">
                    <span className="pp-banner-top">
                        <i className="mdi mdi-alert-outline me-2 text-danger">
                        </i> All staking functions are temporarily paused. Please check back again later.
                    </span>
                </div>
            }
            <div className="page-inner">
                {
                    stakingInfo["isStakingPaused"] &&
                    <div className="container mt-4 text-center d-md-none d-block pp-notice">
                        <span className="pp-banner-top">
                            <i className="mdi mdi-alert-outline me-2 text-danger">
                            </i> All staking functions are temporarily paused. Please check back again later.
                        </span>
                    </div>
                }
                <div className="container pt-5">
                    <RightBar />

                    <div className="mt-5">
                        <h1 className="section-text mb-5 text-center">
                            {selectedTab == 1 && <span>Stake your <span>$CPO</span></span>}
                            {selectedTab == 2 && <span>Unstake your <span>$CPO</span></span>}
                            {selectedTab == 3 && <span>Withdraw your <span>$CPO</span></span>}
                        </h1>
                        <div className="positionboxis">
                            <div className="titleribbon ribbon1">
                                {selectedTab == 1 && <span>Stake</span>}
                                {selectedTab == 2 && <span>Unstake</span>}
                                {selectedTab == 3 && <span>Withdraw</span>}
                            </div>
                            <div className="oramgeboxis">
                                <div className="boxlist mt-5">
                                    <div className="boxitme">
                                        <div className="boxitemimg scrollanimation animated pulse" data-animation="pulse" data-timeout="300">
                                            <img src="/images/1.png" alt="" />
                                        </div>
                                        <div className="boxitemtext">
                                            <div>Number of Stakers</div>
                                            <h3>{stakerCount}</h3>
                                        </div>
                                    </div>
                                    <div className="boxitme">
                                        <div className="boxitemimg scrollanimation animated pulse" data-animation="pulse" data-timeout="325">
                                            <img src="/images/12.png" alt="" />
                                        </div>
                                        <div className="boxitemtext">
                                            <div>Total <span>$CPO</span> Staked</div>
                                            <h3>{helpers.formatNumberDownRound(totalStakedAmount, 2)}</h3>
                                        </div>
                                    </div>
                                    <div className="boxitme">
                                        <div className="boxitemimg scrollanimation animated pulse" data-animation="pulse" data-timeout="340">
                                            <img src="/images/13.png" alt="" />
                                        </div>
                                        <div className="boxitemtext">
                                            <div>APY</div>
                                            <h3>{helpers.formatNumberDownRound((apyPercentage / 100).toString(), 2)}%</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="whiteboxs">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StakingPortalPage;
