import React, { useEffect } from "react";
import * as $ from 'jquery';

const PrivacyPolicyPage = () => {
    const handleScrollTop = (e, el) => {
        e.preventDefault();
        if($(el)){
            $('html,body').animate({
                scrollTop: $(el).offset().top - 100
            }, 'slow');
        }
    }

    useEffect(() => {
        $('body').addClass('no-bg');
        return () => {
            $('body').removeClass('no-bg');
        }
    }, [])
    return <div className="container term-page">
        <div className="text-center mt-5 mb-2">
            <h2>CRYPTOPOLIS GLOBAL LIMITED</h2>
            <div><b>Privacy Policy of <a href="https://cryptopolisgame.com/" target="_blank">https://cryptopolisgame.com/</a></b></div>
            <p>Last revised Date 19<sup>th</sup> October 2021</p>
        </div>

        <div className="mt-5">
            <h3>Introduction</h3>
            <p>This Privacy Policy describes how CRYPTOPOLIS GLOBAL LIMITED (herein referred to as “<b>CRYPTOPOLIS</b>”, “<b>we</b>”, “<b>our</b>”, and “<b>us</b>”) collects, uses, processes, discloses, shares, transfers, and protects the information you provide on <a href="https://cryptopolisgame.com/" target="_blank">https://cryptopolisgame.com/</a> (“<b>Cryptopolis Website</b>”). This Privacy Policy constitutes a part of the Terms and Conditions ("<b>T&Cs</b>"). The terms used in this Privacy Policy will share the same definition as those stipulated in the T&Cs except for others indicated herein.</p>
            <p>We respect and value your privacy as well as the integrity of the information you have provided and/or permitted us to collect. Thus, we endeavour to follow industry standards to ensure the security and confidentiality of your personal information when you use or access our Platform and the Game. </p>
            <p>When accessing, connecting, using, clicking on, or engaging in any products or services provided on the Cryptopolis Platform, you are strongly advised to carefully read, acknowledge, understand, and accept all terms stipulated in this Privacy Policy. We will not use your information, in any shape or form, for any other purpose that has not been indicated in this Privacy Policy or the T&Cs without providing you prior notification or obtaining consent from you.</p>
            <p>This Privacy Policy has incorporated several provisions from the General Data Protection Regulation ("<b>GDPR</b>"), specifically following its personal information processing rules within the European Economic Area (“<b>EEA</b>”).</p>
            <p>This Privacy Policy will inform you about the following matters among several others:</p>
            <ul id="nav">
                <li><a  onClick={(e) => handleScrollTop(e,"#elP1")} href="#elP1">Acceptance of the Privacy Policy</a></li>
                <li><a  onClick={(e) => handleScrollTop(e,"#elP2")} href="#elP2">Our relationship with you</a></li>
                <li><a  onClick={(e) => handleScrollTop(e,"#elP3")} href="#elP3">Personal information that we collect</a></li>
                <li><a  onClick={(e) => handleScrollTop(e,"#elP4")} href="#elP4">How we collect personal information</a></li>
                <li><a  onClick={(e) => handleScrollTop(e,"#elP5")} href="#elP5">How we use personal information</a></li>
                <li><a  onClick={(e) => handleScrollTop(e,"#elP6")} href="#elP6">How we protect personal information</a></li>
                <li><a  onClick={(e) => handleScrollTop(e,"#elP7")} href="#elP7">How long we retain personal information</a></li>
                <li><a  onClick={(e) => handleScrollTop(e,"#elP8")} href="#elP8">Cookies</a></li>
                <li><a  onClick={(e) => handleScrollTop(e,"#elP9")} href="#elP9">Sharing your personal information</a></li>
                <li><a  onClick={(e) => handleScrollTop(e,"#elP10")} href="#elP10">Third-party collection of personal information</a></li>
                <li><a  onClick={(e) => handleScrollTop(e,"#elP11")} href="#elP11">Designated countries and your legal rights</a></li>
                <li><a  onClick={(e) => handleScrollTop(e,"#elP12")} href="#elP12">Rights of the residents of the EEA</a></li>
                <li><a  onClick={(e) => handleScrollTop(e,"#elP13")} href="#elP12">Cross-border Transfer of personal information</a></li>
                <li><a  onClick={(e) => handleScrollTop(e,"#elP14")} href="#elP13">Limitations</a></li>
                <li><a  onClick={(e) => handleScrollTop(e,"#elP15")} href="#elP14">Updates or amendments to the Privacy Policy</a></li>
                <li><a  onClick={(e) => handleScrollTop(e,"#elP16")} href="#elP15">How to contact CRYPTOPOLIS</a></li>
            </ul>
        </div>

        <div className="mt-5" id="elP1">
            <h3>1. Acceptance of the Privacy Policy</h3>
            <p>By accessing, connecting, clicking on, using, or engaging in our Platform and the Game, you indicate and confirm that you have consented and agreed to the terms of this Privacy Policy. We will not use your personal information for any other purpose that has not covered in this Privacy Policy or the T&Cs without providing you prior notification or obtaining your consent.</p>
            <p>If you do not agree, either partially or wholly, to this Privacy Policy, you must immediately discontinue accessing, connecting, using, or engaging in our Platform and the Game. This Privacy Policy does not apply to services offered by other companies or service providers or other websites linked from our Platform.</p>
        </div>

        <div className="mt-5" id="elP2">
            <h3>2. Our relationship with you</h3>
            <p>CRYPTOPOLIS has the relationship with individuals on its Game as described below:</p>
            <p>“User” or “Player” is an individual providing personal information to us via our Cryptopolis Website , Medium pages, social media accounts, or other means. For example, user can start playing Cryptopolis Game by signing up and creating Cryptopolis Account and filling personal information such as username, and e-mail address to complete the signing-up process. our</p>
            <p>Hereinafter, we may refer to user as “<b>you</b>” in this Privacy Policy.</p>
        </div>

        <div className="mt-5" id="elP3">
            <h3>3. Personal information that we collect</h3>
            <p>For the purposes of this Privacy Policy, personal information is information that can be used to identify, describe, relate, or associate with a particular individual. The following are the types of information we collect, store, and process when you use our Platform and the Game:</p>
            <ul style={{ listStyle: 'none' }}>
                <li>
                    <b>3.1 Information provided by you when signing up the Account</b>
                    <p>Prior to connecting or participating in the Platform to our play the Game and purchase its gaming items, you must firstly sign up and open the Account with us. If requested by us, you are required to provide your Telegram username, your Digital Wallet address, and your e-mail address, and other information which can be used to distinguish your identity and to log in our Game.</p>
                </li>
                <li>
                    <b>3.2 Information collected by us for compliance purpose</b>
                    <p>In order to comply with domestic and international industry standards, government rules and regulations, particularly Anti-Money Laundering (“AML”) regulations, Know Your Customer (“KYC”) rules, and Counter-Terrorist Financing (“CFC”) regulations, we may be entitled to collect your personal information from identification documents such as your passport, national identification card, driving’s license, or your visa. Other documents we may use, include but not limited to, your proof of the address, source of fund declaration, and source of wealth etc.  our</p>
                </li>
                <li>
                    <b>3.3 Information collected by us as you access our Platform </b>
                    <ul style={{ listStyle: 'none' }}>
                        <li>
                            <b>3.3.1 Usage Information</b>
                            <p>When you engage or use our Platform and the Game, we will monitor your information in relation to your usage and participation in the Game and the Platform through your device such as your IP address, the type of device you use to access our Platform, device information, which websites you visited before the Platform, browser type, Information about your browser, Date and Time you visit our Platform, Volume of data transmitted and network operator. This information will either be directly obtained by CRYPTOPOLIS or through third party service providers.</p>
                            <p>We collect this type of information to ensure that our interface and/or Platform is seamlessly accessible for all users around the world, except users in the Prohibited Jurisdictions.</p>
                        </li>
                        <li>
                            <b>3.3.2 Communication Information</b>
                            <p>You hereby agree that we are entitled to collect and use or process the personal information you have provided on our Platform or generated through your participation in the Cryptopolis Game for the purpose of being able to communicate with you through messages, requests, emails, live chats, any social media account channels, file attachments in connection with your transactions on the Platform, or any other information related to your contact with CRYPTOPOLIS. If you contact us, we will keep a record of the information shared during the correspondence.</p>
                        </li>
                        <li>
                            <b>3.3.3 Financial Information</b>
                            <p>You hereby agree that, for the purposes stipulated in this Privacy Policy, we are entitled to collect and use the information contained in or related to your financial information when you our access or participate in our Platform and the Game. This includes, without limitation, your transaction history such as your initial cryptocurrency or token amount, your $CPO Token amount, your staking instructions, your Digital Wallet address etc.</p>
                            <p>We collect this financial information to monitor suspicious financial activities to protect you from fraud, resolution of legal cases, as well as any other purposes disclosed in this Privacy Policy.</p>
                        </li>
                    </ul>
                    <p>Parts of Cryptopolis Platform are public, including any information disclosed on our social media accounts, thus you acknowledge that such parts of the Platform may appear on search engines or other publicly available platforms, and may be “crawled”, searched and used by the third parties or other Users and Customers of the Services. Please do not post any information that you do not wish to reveal publicly.</p>
                </li>
            </ul>
        </div>

        <div className="mt-5" id="elP4">
            <h3>4. How we collect personal information</h3>
            <ul style={{ listStyle: 'none' }}>
                <li>
                    <b>4.1 Information automatically collected by us</b>
                    <p>We will collect and process personal information automatically provided by you under the following situations.</p>
                    <ul>
                        <li className="d-flex"><span className="me-2">4.1.1</span><p>when you visit and/or firstly connect/sign up with Cryptopolis Platform and the Game;</p></li>
                        <li className="d-flex"><span className="me-2">4.1.2</span><p>when you voluntarily complete any user survey or provide feedback to us via emails or any other electronic channels including on our social media accounts; </p></li>
                        <li className="d-flex"><span className="me-2">4.1.3</span><p>when you use browser cookies or any other relevant software upon visiting the Cryptopolis Website; and</p></li>
                        <li className="d-flex"><span className="me-2">4.1.4</span><p>other situations where we may automatically collect your information as described in   this Privacy Policy, our T&Cs, or other relevant agreements with you.</p></li>
                    </ul>
                </li>
                <li>
                    <b>4.2 Information collected by the third-party</b>
                    <p>We will collect your personal information from the third parties as required or permitted to the extent of applicable laws. Information from the third-party sources will include, but will not limited to, public databases, ID verification partners, KYC Verification partners, blockchain data, marketing partners and resellers, advertising partners, and analytics providers.</p>
                </li>
                <li>
                    <b>4.3 Anonymized and Aggregated Data </b>
                    <p>We will also collect other Information in the form of anonymized and aggregate information where all information will be combined and then removed of personally identifiable information, making it unusable for the identification of specific individuals. Aggregated data could be derived from your personal data, but it is not considered as personal data under applicable laws. For example, we may aggregate your usage data to calculate the percentage of users accessing a specific features of the Platform and the Game. However, if we combine or connect aggregated data with your personal data so that it can directly or indirectly identify you, you acknowledge that we treat the combined data as personal data which will be used in accordance with this Privacy Policy.</p>
                    <p>We use anonymized or aggregate users' information for the purposes stipulated in this Privacy Policy at any time, including gaining better understanding of users' needs and behaviours, conducting business intelligence and marketing initiatives, and detecting security threats. In this regard, we reserve the right to implement innovative technologies as they become available to be used for strategic planning and operational processes.</p>
                </li>
            </ul>
        </div>

        <div className="mt-5" id="elP5">
            <h3>5. How we use personal information</h3>
            <p>Your personal information will be made available to need-to-know basis to CRYPTOPOLIS, our Affiliate, our Partners and/or within our group of companies. This will include, but not be limited to, the employees and/or any persons or third parties working for CRYPTOPOLIS who are responsible for using and protecting your personal information.</p>
            <p>We use and process your personal information for the following purposes or in the following ways:</p>
            <ul style={{ listStyle: 'none' }}>
                <li>
                    <b>5.1 To provide our Services</b>
                    <p>We respect data protection principles, and process personal data only for specified, explicit, and legitimate purpose for which such personal data were provided. We primarily use your personal information, either collected or delivered, in order to enable your use of Our Platform and the Game (including, but not limited to, processing transactions), to improve the efficiency of  day-to-day services, to provide you with a personalised experience, and to verify your identity.</p>
                </li>
                <li>
                    <b>5.2 To protect users</b>
                    <p>We use the information collected to protect our Platforms, Users' information, and archives. We also use IP addresses and cookies to detect suspicious activities and to protect against automated abuse such as spam, phishing, and Distributed Denial of Service (“DDoS”) attacks, and other security risks.</p>
                </li>
                <li>
                    <b>5.3 To comply with regulations and compliance</b>
                    <p>For our legitimate interests, we will use the information in compliance with our legal obligations, global and local industry standards, and our AML/KYC/CTF procedures. In the case where it is strictly necessary (i.e., to protect the vital interests of the users or other natural persons, to prevent or mitigate fraud, to fulfil the purpose of public interest, or to pursue our reasonable interests), we may use and process your personal information without obtaining your consent. You hereby expressly authorise CRYPTOPOLIS to disclose any and all information relating to you in CRYPTOPOLIS’s possession to any law enforcement or government officials upon a valid request. </p>
                </li>
                <li>
                    <b>5.4 For measurement, research and development purposes</b>
                    <p>We actively measure and analyse your information to understand the way you use and interact with our Platform and the Game, and to provide you with the unique and personalized experience. For example, we allow you to use social media plugins on our Platform (e.g., Telegram, Twitter, etc.), we keep track of your preferences such as display name, time zone, and so on. This review is continuously conducted by our operation teams to continuously improve our Platforms' performance and to resolve issues with user experience.</p>
                    <p>In addition, we use such information to monitor trends, and improve our Game, our administration, the content and layout of the Cryptopolis Website, and to develop new gaming items or new challenges for you.</p>
                </li>
                <li>
                    <b>5.5 For communication purposes</b>
                    <p>We use your personal information, collected or derived from your communication devices or your email address, to interact with you directly, to provide you with necessary support, and/or to keep you informed of wallets connected, transactions, staking instructions, security details, as well as other aspects. All direct communications will be appropriately maintained at CRYPTOPOLIS, or the service providers designated by CRYPTOPOLIS, to be reviewed for accuracy, retained as evidence, or used to perform other statutory requirements or other obligations as stipulated in this Privacy Policy and the T&Cs.</p>
                </li>
                <li>
                    <b>5.6 To enforce our T&Cs, and other relevant agreements</b>
                    <p>Personal information is also used to enforce our T&Cs continuously and actively among, and other agreements relating to the Game on the Platform. Activities in this regard include, but are not limited to, reviewing, processing, verifying, investigating, mitigating and preventing any potentially prohibited or illegal activities that may violate preceding provisions, or disclose relevant information to third parties in accordance therewith. In light of this, CRYPTOPOLIS shall be entitled to freeze/close/delete Accounts or any user activity on the Cryptopolis Website as necessary to any User found to be engaged in any activities that violate our T&Cs, and other agreements.</p>
                </li>
                <li>
                    <b>5.7 For marketing and advertising</b>
                    <p>We will share your personal information with our marketing partners for the purposes of targeting, modelling, and/or identifying analytics as well as marketing and advertising. We may send you marketing communications to alert you about new Services, yet you can opt-out of our marketing activities at any time.</p>
                </li>
                <li>
                    <b>5.8 For other purposes</b>
                    <p>Provided that we need to process or use your personal information for other purposes, not stipulated in this Privacy Policy, we will notify or request your consent to use such information. We will not use your personal information for purposes other than the purposes stipulated in this Privacy Policy without your prior consent except where it is necessary for our legitimate interests (or for our Affiliate, our Partners and/or our group of companies) and your interests and fundamental rights do not override those interests.</p>
                </li>
            </ul>
        </div>

        <div className="mt-5" id="elP6">
            <h3>6. How we protect personal information</h3>
            <p>CRYPTOPOLIS takes reasonable care to protect the CRYPTOPOLIS’s security as well as your personal information from loss, misuse, disclosure, alteration, and destruction. We take reasonable steps to maintain physical, technical, and employ procedural safeguard to ensure the confidentiality, integrity of your personal information. The safeguards include the use of firewalls, and data encryption, enforcing physical access controls to our premise and files, and limiting access to personal information only to those employees, agents or third parties who need access to that information to process it for us.</p>
            <p>We also implement certain reasonable security measures to protect your personal information from unauthorized access, and such security measures are in compliance with the security practices and procedures as prescribed under the applicable laws.</p>
            <p>However, you agree and acknowledge that it is impossible to fully guarantee the security of your personal information by implementing the above-mentioned safeguard and measures. It is not absolute protection to your personal information and by accessing the Platform and the Game.</p>
            <p>You agree that we will not hold any responsibility for the acts committed by those who gain unauthorized access or abuse your information and services. In addition, we will not hold any responsibility for any act committed by the third-party service providers who are contractually engaged with us to maintain an appropriate security measure for protecting your personal information. Thus, you are recommended to understand this responsibility and to independently take safety precautions to protect your personal information, particularly your credential information such your Digital Wallet address. You hereby agree that we will not be liable for any information leakage and other damage, or loss not caused by our intention or gross negligence, including, but not limited to, hacker attacks, power interruptions, or unavoidable technical failures.</p>
        </div>

        <div className="mt-5" id="elP7">
            <h3>7. How long we retain personal information</h3>
            <p>You are aware that your personal information will continue to be stored and retained by us while you have been using, accessing or participating in the Cryptopolis Platform and the Game, and after your discontinue your Account for a reasonable period of time.</p>
            <p>In addition, we may be required by applicable laws and regulatory requirements to retain certain information, including your personal information, your identification verification materials, information relevant to AML/KYC/CTF procedures, information related to your $CPO Tokens, your Account, and other agreements between us and third-parties, account statements, and other records after you stop accessing or participating in the Platform and the Game.</p>
        </div>

        <div className="mt-5" id="elP8">
            <h3>8. Cookies</h3>
            <p>We will use cookies and other technologies or methods of web and analytic tools to gather, store, and analyse certain information related with your access to and activities through the Platform..</p>
            <p>A "cookie" is a small piece of information that a website assigns to your device while you are viewing a website. Cookies are beneficial and may be used for various purposes. These purposes include, among other things, allowing you to navigate between pages efficiently, enabling automatic activation of certain features, remembering your preferences and making the interaction between you and the Services quicker, easier and smoother. our Website will use the following types of cookies:</p>
            <ul style={{ listStyle: 'none' }}>
                <li>
                    <b>8.1 Strictly Necessary Cookies</b>
                    <p>These cookies are essential to enable you to log in, navigate a website, and use its features or to provide a service requested by you. We will not need to obtain your consent in order to use these cookies.</p>
                </li>
                <li>
                    <b>8.2 Functionality Cookies</b>
                    <p>These cookies allow the website to remember choices you make (such as your username, language, or the region you reside in) and provide enhanced, more personal features. The information these cookies collect remains anonymous, and they cannot track your browsing activity on other websites.</p>
                </li>
                <li>
                    <b>8.3	Performance cookies</b>
                    <p>These cookies collect information about how you use a website, for example, which pages you go to most often, how much time you spend on a page, record difficulties you may experience while using the website such as error messages. All information collected by these cookies is aggregated and therefore anonymous. It is only used to improve the efficiency of the website.</p>
                </li>
                <li>
                    <b>8.4 Targeting Cookies or Advertising Cookies</b>
                    <p>These cookies are used to deliver advertisements tailored to you and your interests specifically. They are also used to limit the number of times you see an advertisement as well as help measure the effectiveness of the advertising campaign. These cookies remember that you have visited a website, and this information is shared with other organizations such as advertisers. Quite often targeting or advertising cookies will be linked to the sites’ functionality provided by the other organizations. </p>
                </li>
            </ul>
            <p>You may remove these cookies by following the instructions of your device preferences. However, if you choose to disable cookies, some features of the Game may not operate properly or become inaccessible, and your online experience may be limited. For Further information please visit our Cookies Policy.</p>
        </div>

        <div className="mt-5" id="elP9">
            <h3>9. Sharing your personal information</h3>
            <p>We will not rent, sell, or disclose your personal information to any third parties, except to those who require access to the information to perform their tasks and duties under the binding agreements executed with us and to share with third parties who have a legitimate purpose for accessing it.</p>
            <p>We may share, transfer, disclose, or allow access to your personal information to the following third parties for the purposes described below:</p>
            <ul>
                <li><p>We may disclose personal information to the third parties in order to administer or process a transaction, or services you have authorized or requested, or in the context of facilitating the execution of a transaction, or we may disclose personal information to third parties that provide supporting services, or analytical information for the purpose of the Game improvement, only where we have a lawful basis to do so or under an agreement or legal requirements for the transfer of personal information.</p></li>
                <li><p>We will be required to verify your identities by applicable laws and regulatory requirements and rely on third-party services to perform these verifications. personal information that you provide to us during the initial connecting process is passed to these services at KYC Verification process and on an ongoing basis thereafter.</p></li>
                <li><p>We may disclose or transfer a part of your personal information we collect if we are involved in a business transition or any merger and/or acquisition (M&A) projects. In such M&A event, your personal information might be among the assets transferred, but it will be protected and secure in accordance with this Privacy Policy. </p></li>
                <li><p>We may share your personal information with law enforcement, and government officials when we are compelled to do so by a subpoena, a valid court order to prevent financial loss, to report suspected illegal activity or to investigate violations of any of our T&Cs or any other applicable policies.</p></li>
                <li><p>We may share your personal information with third-party processors acting on behalf of CRYPTOPOLIS or engaged by CRYPTOPOLIS to process your personal information for CRYPTOPOLIS, our Affiliate and/or our Partners.</p></li>
            </ul>
            <p>All Affiliate and third parties with whom we share your personal information have their privacy policies. However, they will be expected to protect this information in a manner that aligns with the provisions described in this Privacy Policy.</p>
        </div>

        <div className="mt-5" id="elP10">
            <h3>10. Third-party collection of personal information</h3>
            <p>This Privacy Policy only addresses the use and disclosure of the personal information we collect from you. To the extent that you disclose your information to other parties through the use or participation in the Platform and the Game such as by clicking on a link to any other websites of our partner networks, different rules regarding Privacy Policies may apply to their use or disclosure of the personal information you disclose to them.</p>
            <p>You hereby acknowledge that we will not be responsible for the products, services, or descriptions of products or services that you receive from the third-party websites or to the content or privacy practices of the third-party websites. Also, this Privacy Policy will not be applied to any such third-party products and services that you access through our Platform. You are knowingly and voluntarily assuming all risks of using third-party websites to purchase products or services, and you agree that we will have no liability whatsoever concerning such third-party websites and your usage of them.</p>
            <p>Your relationship with these third parties and their services and tools is independent of your relationship with us. These third parties may allow you to permit/restrict the information that is collected. It may be in your interest to individually restrict or enable such data collections.</p>
        </div>

        <div className="mt-5" id="elP11">
            <h3>11. Designated countries privacy right and your legal rights</h3>
            <p>This provision applies to Users of the Platform and the Game that are located in the EEA, United Kingdom and/or Switzerland (herein collectively referred as “<b>Designated Countries</b>”) at the time of Data or personal information being collected. We may ask your information regarding your nationality, your location, which country you are in when you use our Services or Platform, or we may rely on your IP address. We cannot apply this provision to Users who do not provide information about the location or obfuscates location information so as not to appear located in the Designated Countries. </p>
            <p>We use and process your personal information based on a valid lawful basis of the GDPR and any equivalent regulations (collectively referred as “<b>Data Protection Laws</b>”). The lawful basis for processing your personal information includes (i) your consent, (ii) performance of a contract, (iii) a legitimate interest, (iv) a compliance with legal obligations, (v) protection your vital interest, and (vi) public task.</p>
            <p>In the event of any conflict or inconsistency between any term in this provision and other terms contained in this Privacy Policy, the term in this provision will govern and control over Users in the Designated Countries.</p>
            <p>Under certain circumstances when you use our Services, you have the rights under the Data Protection Laws in relation to your personal information. These rights include the followings:</p>
            <ul style={{ listStyle: 'none' }}>
                <li>
                    <b>11.1 Right to be informed</b>
                    <p>You reserve the right to be informed about the collection, use, and process of your personal information.</p>
                </li>
                <li>
                    <b>11.2 Right of access</b>
                    <p>You reserve the right to make a request for a copy of the personal information we hold about you and specific information regarding our processing of this information.</p>
                </li>
                <li>
                    <b>11.3 Right to rectify</b>
                    <p>You reserve the right to request us to update, correct, or complete your personal information that you believe to be outdated, inaccurate, or incomplete. You will rectify at any time by informing us via our contact channels.</p>
                </li>
                <li>
                    <b>11.4 Right to erasure (“Right to be Forgotten”)</b>
                    <p>You may request to have your personal information deleted from our record where (i) your personal information is no longer necessary to be used for the Services or on the Platform, (ii) personal information was collected in relation to processing that you previously consented to, but later withdrew such consent, or (iii) your personal information was collected in relation to processing activities to which you object, and there are no overriding legitimate grounds for our processing.</p>
                    <p>Please be informed that the Right to be Forgotten mentioned above is not an absolute right. We are compelled to fulfil your request only where the retention of your personal information might cause an infraction of the GDPR or applicable law to which ewe are subject. We shall exercise reasonable efforts in having your personal information erased to the extent required by the GDPR or applicable laws including in communicating erasure to our recipients of your personal information unless that proves to be impossible or a disproportionate effort.</p>
                </li>
                <li>
                    <b>11.5 Right to data portability</b>
                    <p>You reserve the right to request to transfer a machine-readable copy of your personal information to you or the third-party of your choice. We will provide you, or third-party, your personal information in a machine-readable format. This right only applies to personal information you have consented us to use.</p>
                </li>
                <li>
                    <b>11.6 Right to restrict processing</b>
                    <p>You may request us to restrict or suppress the processing of your personal information under certain circumstances as follows:</p>
                    <ul>
                        <li><p>to contests the accuracy of the personal information;</p></li>
                        <li><p>when the processing is considered unlawful, but you do not wish to have your personal information erased;</p></li>
                        <li><p>where we no longer need to process your personal information, but the information must be retained for the establishment, exercise or defence of legal claims; and</p></li>
                        <li><p>where you have objected to our processing your personal information, but we need to determine whether our legitimate interest overrides your objection.</p></li>
                    </ul>
                </li>
                <li>
                    <b>11.7 Right to object</b>
                    <p>You may object to our reliance on our legitimate interests as the basis of our processing of your personal information that impacts your rights. You may also object to our process of your personal information for direct marketing purposes.</p>
                </li>
                <li>
                    <b>11.8 Right to withdraw consent ("Opt-out")</b>
                    <p>You reserve the right to withdraw your consent at any time where we are relying on it to process your personal information. Withdrawing your consent does not affect the lawfulness of our processing of your personal information prior to withdrawing.</p>
                </li>
            </ul>
            <p>If you wish to exercise these rights as aforementioned, please inform and contact us via our Platform, or send a request to our social media accounts.</p>
            <p>Please also be noted that there are some limitations when you wish to exercise any one of these individual rights. We may limit your individual rights in the following situations:</p>
            <ul>
                <li><p>Where denial of access is required by laws;</p></li>
                <li><p>When granting access would have a negative impact on others’ privacy;</p></li>
                <li><p>In order to protect our rights and properties;</p></li>
                <li><p>Where the request burdensome.</p></li>
            </ul>
        </div>

        <div className="mt-5" id="elP12">
            <h3>12. Cross-border transfer of personal information</h3>
            <p>As a part of your use of and to ensure better and seamless delivery of the Game to you, we may store, process, analyse, transfer your personal information in location globally, including countries other than your home jurisdiction, and/or locations outside the Designated Countries where there may have data protections with less protection than the EU GDPR or the equivalent data protection laws. </p>
            <p>Our Platform and the Game contain the transmission of your personal information to or to be transferred to or to be processed outside of the country where you are located or outside the Designated Countries, it is not suggestible if your personal information is restrictive. </p>
            <p>However, if you wish to continue using our Game outside the Designated Countries and your home locations, we will ensure that we will transfer your personal information only to country that has the required contractual provisions for transferring personal information in place with the third parties to which your Information is transferred, or to country where is approved by the EU authorities as providing an adequate level of data protection or enter into legal agreements ensuring an adequate level of data protection in the jurisdiction of the party receiving the information. In this manner, you hereby expressly consent to this transfer, and agree that we will not be responsible for any additional T&Cs, policies or any other guidelines implemented by any party receiving the information.</p>
        </div>

        <div className="mt-5" id="elP13">
            <h3>13. Limitation</h3>
            <ul style={{ listStyle: 'none' }}>
                <li className="d-flex">
                    <b>13.1</b>
                    <p className="ps-3">Our Platform and the Game are not designed for the individuals who are under the age of 18 or under the legal age to provide consent under the applicable laws of country or jurisdiction where you are using our Game. If you are not over 18 or of the legal age of your country, you will not provide any personal information to us, or to engage in our Game. We reserve the right to access and verify any personal information collected from you. If we are aware that you are under 18 or under the legal age who already shared your personal information with us, we will then discard such information and block your access to the Platform and the Game immediately.</p>
                </li>
                <li className="d-flex">
                    <b>13.2</b>
                    <p className="ps-3">We cannot guarantee that any losses, misuses, unauthorized accession, or alteration of your personal information will not occur. You hereby agree that you play a vital role in protecting your personal information, including your credentials</p>
                </li>
            </ul>
        </div>

        <div className="mt-5" id="elP14">
            <h3>14. Updates or amendments to the Privacy Policy</h3>
            <p>We will revise and update this Privacy Policy periodically, at our sole discretion, and the most current version will be published on Cryptopolis Website or on the Platform (as reflected in the "Last Revised" heading).</p>
            <p>In the event of any material change which may affect your usage on our Game, we will notify you by means of a prominent notice in advance of such change coming into effect. A prominent notice may include sending through your email address or other communication channels you provide to us or providing you with an in-application notice such as a banner or pop-up alerting you of the change on the Website. We strongly encourage you to review this Privacy Policy actively. If you do not agree, either partially or wholly, the revised Privacy Policy, you should discontinue accessing or using our Game Your continued access to and use of our Game after any changes to this Privacy Policy constitutes your consent to any changes and agree to continue using the Game.</p>
            <p>Furthermore, we may provide you with the “just-in-time” disclosures or additional information about our data collection process, purposes for processing, or other information with respect to collection of your personal information. The purpose of the just-in-time notification is to supplement or clarify our privacy practice or provide you with additional choices about how we process your personal information.</p>
        </div>

        <div className="mt-5" id="elP15">
            <h3>15. How to contact CRYPTOPOLIS</h3>
            <p>For any questions regarding this Privacy Policy, your personal information collected or proceeded by us, or in case you would like to exercise one of your legal privacy rights as stipulated in Clause 11, please submit your requests to any social media account channels and visit our website at <a href="https://cryptopolisgame.com/" target="_blank">https://cryptopolisgame.com/</a>.</p>
        </div>
        <a href="#nav" className="top" title="Scroll to navigation" onClick={(e) => handleScrollTop(e,"#nav")}>»</a>
    </div>
}
export default PrivacyPolicyPage;