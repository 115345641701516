import React from "react";

const TermOfUsePage = () => {
    return <div className="container term-page">
        <div className="text-center mt-5 mb-2">
            <h2>CRYPTOPOLIS GLOBAL LIMITED</h2>
            <div><b>Terms and Conditions of the  <a href="https://cryptopolisgame.com/" target="_blank">https://cryptopolisgame.com/</a></b></div>
            <p>Last revised Date 19<sup>th</sup> October 2021</p>
        </div>

        <div className="mt-5">
            <h3>1. Introduction</h3>
            <p>These Terms and Conditions (herein referred to as “these Terms”) govern the conditions in which you, as a User (herein referred to as “<b>User</b>” or “<b>Player</b>” or “<b>you</b>”) of the https://cryptopolisgame.com/, are permitted to use the platform and participate in the Cryptopolis Game provided by CRYPTOPOLIS GLOBAL LIMITED (“<b>CRYPTOPOLIS</b>” or “<b>we</b>” or “<b>us</b>”), a company incorporated under the Laws of the Republic of Seychelles. By accessing, registering, downloading, using, clicking on, or purchasing any NFTs, any objects and services made available by CRYPTOPOLIS via the https://cryptopolisgame.com/ website, and a Cryptopolis mobile application (“<b>Website</b>”), you hereby accept and agree to all the terms set forth herein.</p>
            <p>You and CRYPTOPOLIS are referred to separately as a “Party” and collectively as “Parties”.</p>
            <p>You are strongly advised to carefully read these Terms as well as the provisions detailed in our Privacy Policy prior to using the Website. By using the Website in any capacity, you agree that: (i) You have read and familiarized yourself with these Terms; (ii) You understand these Terms; and (iii) You agree to be bound by these Terms when using the Website. If you do not agree to these Terms, you must immediately stop using the Website.</p>
            <p>We reserve the right to modify or amend these Terms, the Website, the services or any content on either one of the platforms from time to time, including for security, legal, or regulatory reasons, as well as to reflect updates or changes to the services and products or functionality of the Website. You are advised to check these Terms periodically to ensure that you are cognizant of the current versions and comply with them. Users of the Website are bound by these changes which will take immediate effect after the revised versions of these Terms have been published on the Website. Through your continued use of or interaction with the Website, tools, and information made available on these platforms, you hereby agree to be bound by the provisions highlighted in the subsequent versions.</p>
            <p>We will provide a notification on the Website specifying that changes have been made to these Terms whenever they occur. By accepting the notification, you accept that we have provided you with sufficient notice of any changes. You should seek professional advice regarding any possible tax and legal requirements you must comply with in relation to your use of the Website, tools, services, and products provided by CRYPTOPOLIS.</p>
        </div>

        <div className="mt-5">
            <h3>2. Definitions</h3>
            <p><b>"AML"</b> refers to Anti-Money Laundering.</p>
            <p><b>"Applicable Laws"</b> refers to acts, statutes, regulations, ordinances, treaties, guidelines, and policies issued by governmental organizations or regulatory bodies, including, but not limited to, the governing law stipulated under Laws of the Republic of Seychelles.</p>
            <p><b>"Cryptopolis Platform"</b> refers to the Website, information, and any official social media channels related with CRYPTOPOLIS communication made available to access CRYPTOPOLIS's services and products, which may be subject to periodic revision (herein collectively referred to as “Platform”). </p>
            <p><b>"Cryptopolis Account"</b> refers to a virtual account the User created on the Website that allows the User to access or participate in Cryptopolis Game.</p>
            <p><b>“Cryptopolis Game or Game”</b> refers to a social NFT based game where the Users can meet their friends, wining wager matches, buy and sell their NFT(s), collect and earn $CPO TOKEN, through the challenges, and the minigames created by CRYPTOPOLIS. The Game is deployed on a proven and powerful Binance Smart Chane Network.</p>
            <p><b>"$CPO Token"</b> means a cryptographic digital and in-game token which can be used for making NFT(s) transactions on the NFT marketplace provided by CRYPTOPOLIS. Users can also buy or sell $CPO Token(s) straight with fiat currency. </p>
            <p><b>"CFT"</b> refers to Combating the Financing of Terrorism.</p>
            <p><b>"Confidential Information"</b> refers to any non-public, proprietary information or documents of or related to the User or CRYPTOPOLIS (whether in writing, orally or by any other means) by or on behalf of the User to CRYPTOPOLIS and which if disclose in tangible or intangible form is marked confidential (including in visual, oral, or electronic form) relating to us or any other User that was previously a User, which is provided or disclosed through the Cryptopolis Platform (or to any employees or agents) in connection with the use or participate in the Cryptopolis Game, and the Platform. </p>
            <p><b>"Content"</b> refers to all content generated by CRYPTOPOLIS, including game characters, logos, identifying marks, images, illustrations, designs, icons, photographs, videos, text, any written or multimedia materials, services and products, advertisements, software, code, data, files, archives, folders, or available downloaded or purchased on the Cryptopolis Platform. </p>
            <p><b>"Prohibited Jurisdictions"</b> specifically refer to the United States of America (including its territories, and dependencies, and any state of the United States), Albania, Barbados, Botswana, Burkina Faso, Cambodia, Democratic People's Republic of Korea, Haiti, Iran, Jamaica, Morocco, Myanmar (Burma), Nicaragua, Pakistan, Panama, Philippines, Senegal, South Sudan, Syria, Thailand, Uganda, and Yemen, Zimbabwe.</p>
            <p><b>"Wallet(s)"</b> refers to any digital wallet that is used for signing up the Cryptopolis Account, for participating in the Game, and for storing $CPO Tokens such as MetaMask and Trust Wallet.</p>
        </div>

        <div className="mt-5">
            <h3>3. General Provisions</h3>
            <ul style={{ listStyle: 'none' }}>
                <li>
                    <b>3.1 Contractual Relationship</b>
                    <p>These Terms constitute a valid and binding agreement between you and CRYPTOPOLIS. The binding obligations stipulated in these Terms are enforceable.</p>
                </li>
                <li>
                    <b>3.2 Revision and Amendments</b>
                    <p>CRYPTOPOLIS reserves the right to revise, amend, or update any clauses and provisions stipulated in these Terms in its sole discretion at any time. CRYPTOPOLIS will notify the revision or amendment of such clauses or provisions by updating these Terms and specify the 'Last Revised Date' displayed on a page of Terms and Conditions. Any revisions and updates on these Terms will be automatically effective upon their publication on the Cryptopolis Platform, which includes the Website. Therefore, your continuity of accessing, using, or entering the Cryptopolis Platform and the Game will be deemed that you agree to have read, understood, and accepted all revised terms and provisions. If you do not agree on any revised or updated terms and provisions, you should immediately stop accessing, using, or entering our Platform and the Game. You are encouraged to frequently and carefully review these Terms to ensure that you understand these Terms.</p>
                </li>
                <li>
                    <b>3.3 Privacy Policy</b>
                    <p>You acknowledge and confirm that you have read, understood, and agreed to provisions stipulated in the Privacy Policy, which will explain how CRYPTOPOLIS treats your information and protects your privacy when accessing, using, or entering to our Platform. By using the Cryptopolis Platform, you hereby agree that CRYPTOPOLIS may collect and use your information provided to us as set forth in the Privacy Policy for the purpose of CRYPTOPOLIS services and the Game.</p>
                </li>
                <li>
                    <b>3.4 The Use of the Website</b>
                    <ul style={{ listStyle: 'none' }}>
                        <li className="d-flex">
                            <span className="me-3">3.4.1</span>
                            <p>You will comply with all applicable domestic and international laws, statutes, ordinances, and regulations applicable to your use of the Website. You will not use the Website or its content for any unlawful purposes, including but not limited to copyright laws or labour laws.</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">3.4.2</span>
                            <p>You will be responsible for obtaining the data network access necessary to use the Website. Your network's data and rates and fees may apply if you access or use the Website from a wireless-enabled device, and you will be responsible for such rates and fees.</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">3.4.3</span>
                            <p>You will be responsible for acquiring and updating compatible hardware or device necessary to access and use the Website and any updates thereto. In addition, the Website may be subject to malfunctions and delays inherent in the use of the Internet and electronic communications.</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">3.4.4</span>
                            <p>You will be responsible for keeping your credential information, including your username, password, e-mail address, your Digital Wallet address, and your private key to your Cryptopolis Account secure. </p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">3.4.5</span>
                            <p>You will be solely responsible for your conduct and any content, comments that you submit, post, and display on the Website. You further agree that your comments on our Platform or on our social media accounts will not contain libellous or otherwise unlawful, abusive or obscene materials.</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">3.4.6</span>
                            <p>You will not upload any images, graphics, photographs, videos, or content that is deemed unlawful, offensive, malicious, threatening, libellous, defamatory, obscene, or otherwise objectionable or violates any party's intellectual property.</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">3.4.7</span>
                            <p>You will not abuse, harass, threaten, impersonate, or intimidate other Users of the Website.</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">3.4.8</span>
                            <p>You will not modify, adapt, or hack the Website or modify another Website to imply that it is associated with the Website falsely.</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">3.4.9</span>
                            <p>You will not crawl, scrape, or otherwise cache any content from the Website including but not limited to user profiles, images, graphics and photographs. You will agree not to use any automated data collection methods, data mining, robots, or scraping or any data gathering methods of any kind on the Website.</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">3.4.10</span>
                            <p>You will not transmit any malware, worms or viruses or any code of a destructive nature to the Website.</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">3.4.11</span>
                            <p>You will not use the Website or any of its contents for advertising or soliciting, for any other commercial, political, or religious purpose, or to compete, either directly or indirectly with CRYPTOPOLIS.</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">3.4.12</span>
                            <p>You will not send through the website to any third party which its details are available in the Website and you will not publish, post and/or upload any content which is offensive, infringing proprietary rights including content which infringes copyrights or trademarks, content which is illegal, defaming and/or violating one’s privacy, and computer software and/or computer code and/or Website and/or Service containing viruses, malicious software and harmful Website and/or Services and products, passwords, usernames and other identifying data enabling the use of computer software, digital files, Websites and services and products requiring registration of payment, freeware of registration, any information containing harmful, unlawful, libellous and/or racist content, information which may mislead the third party and/or any information which harms any acceptable norms of using the internet and which may harm the internet users and specifically website  Users.</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">3.4.13</span>
                            <p>If CRYPTOPOLIS has the reasonable ground to believe and determine that you have engaged in any use of the Website in breach of this Clause 3.4, CRYPTOPOLIS may address such breach through an appropriate sanction, in its sole and absolute discretion. Such sanction may include, but is not limited to, making a report to any government, law enforcement, or other authority, without providing any notice to you about any such report, suspending your access to the Cryptopolis Platform and the Game, or terminating/deleting your Cryptopolis Account. CRYPTOPOLIS may, at its sole and absolute discretion, take any action it deems appropriate to comply with Applicable Laws or the laws of any other jurisdictions. In addition, should your actions or in action result in the imposition of economic costs to CRYPTOPOLIS, you will pay an amount to CRYPTOPOLIS so as to render CRYPTOPOLIS whole, including without limitation, the amount of taxes or penalties that might be imposed on CRYPTOPOLIS.</p>
                        </li>
                    </ul>
                </li>

                <li>
                    <b>3.5 Links to and from the Website</b>
                    <ul style={{ listStyle: 'none' }}>
                        <li className="d-flex">
                            <span className="me-3">3.5.1</span>
                            <p>You may, through hypertext or other computer links, gain access from the other website to another website operated by persons other than us (‘third-party link’). Such hyperlinks are provided for your convenience.</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">3.5.2</span>
                            <p>A link from any third party does not mean that we endorse or approve the content on such website or the operator of such third-party website. You are solely responsible for determining the extent to which you may use or rely upon any content at any other third-party websites which you have accessed from the Cryptopolis Platform. We have no control over the content or the accuracy of these sites or resources and we accept no reasonability for them or for any loss or damage that may arise from your use of them.</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">3.5.3</span>
                            <p>We assume no responsibility for the use of, or inability to use, any third-party software, other materials, or contents posted and/or uploaded on such website and will have no liability whatsoever to any person or entity for their use of, or inability to use, any such third-party content. Complains, claims, or questions regarding these third-party website or services must be filed to third parties directly.</p>
                        </li>
                    </ul>
                </li>

                <li>
                    <b>3.6 Availability of the Website</b>
                    <ul style={{ listStyle: 'none' }}>
                        <li className="d-flex">
                            <span className="me-3">3.6.1</span>
                            <p>As electronic and technological platform is subject to interruption or breakdown, access to the Website is offered on an "as is" and "as available" basis only.</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">3.6.2</span>
                            <p>We reserve the right to limit the availability of the Website to any person, geographic area, or jurisdiction we so desire and/or terminate your access to and use of the Website, at any time and in our sole discretion.</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">3.6.3</span>
                            <p>We may, at our sole discretion, impose limits or restrictions on the use you make on the Cryptopolis Platform and the Game. Further, for commercial, security, technical, maintenance, legal or regulatory reasons, or due to any breach of these Terms, we may withdraw your accessibility to the Website at any time and without notice to you.</p>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <div className="mt-5">
            <h3>4. Intellectual Property</h3>
            <ul style={{ listStyle: 'none' }}>
                <li className="d-flex">
                    <span className="me-3">4.1</span>
                    <p>You acknowledge that you are responsible for any submissions of all contents, comments, remarks, suggestions, materials, feedbacks, or other information provided through the Cryptopolis Platform, and your username or password, and you, not us, have full responsibility for such submissions, including their accuracy, legality, reliability, appropriateness, originality, and copyright. Notwithstanding the foregoing, we have the right to refuse to post, remove, edit, or abridge any submission for any reason and to disclose any submission as we deem necessary, including without limitation to achieve compliance with any applicable laws, regulations, legal processes, or governmental requests upon a subpoena or a valid court order, all in our sole discretion.</p>
                </li>
                <li className="d-flex">
                    <span className="me-3">4.2</span>
                    <p>Subject to Clause 4.4 below, as between you and us, we solely own all present and future copyright, registered and unregistered trademarks, design rights, unregistered designs, database rights and all other present and future intellectual property rights and rights in the nature of intellectual property rights that exist in or in relation to the Website and the Game.</p>
                </li>
                <li className="d-flex">
                    <span className="me-3">4.3</span>
                    <p>If and to the extent that any such intellectual property rights are vested in you by operation of law or otherwise, you agree to do any and all such acts and execute any and all such documents as we may reasonably request in order to assign such intellectual property rights back to us.</p>
                </li>
                <li className="d-flex">
                    <span className="me-3">4.4</span>
                    <p>As long as the Website is active, you agree that you will grant us a worldwide, exclusive, royalty-free, non-terminable license to use, copy, distribute, publish, and transmit such data in any manner.</p>
                </li>
                <li className="d-flex">
                    <span className="me-3">4.5</span>
                    <p>We will not warrant or represent that the content of the Website does not infringe the rights of any third party.</p>
                </li>
                <li className="d-flex">
                    <span className="me-3">4.6</span>
                    <p>You undertake that you will not at any time, after you have provided a unique Telegram username to us, divulge or communicate to any person (except to professional representatives or advisors as may be required by law or any legal or regulatory authority) any Confidential Information, and will use best endeavours to prevent the unauthorized publication or disclosure of any Confidential Information, and will only use such Confidential Information for the purposes of entering, considering or making transactions through the Cryptopolis Platform. </p>
                </li>
            </ul>
        </div>

        <div className="mt-5">
            <h3>5. Copyright and Trademark</h3>
            <ul style={{ listStyle: 'none' }}>
                <li className="d-flex">
                    <span className="me-3">5.1</span>
                    <p>Website content must not be copied or reproduced, modified, redistributed, used, created for derivative works, or otherwise dealt with for any other reason without our express written consent from CRYPTOPOLIS.</p>
                </li>
                <li className="d-flex">
                    <span className="me-3">5.2</span>
                    <p>Third parties participating in the other services and products provided to CRYPTOPOLIS, may permit CRYPTOPOLIS to utilise Trademarks, Copyrighted Material, and other Intellectual Property associated with their businesses. </p>
                </li>
                <li className="d-flex">
                    <span className="me-3">5.3</span>
                    <p>Except where necessary for and incidental to personally viewing the Website through your web browser, or as permitted by these Terms, no part of the Website may be reproduced, stored (for any period of time) in an electronic or any other retrieval system, modified, adapted, uploaded to a third-party location, framed, performed in public, or transmitted, in any form by any process whatsoever, without our specific prior written consent from CRYPTOPOLIS.</p>
                </li>
            </ul>
        </div>

        <div className="mt-5">
            <h3>6. Cryptopolis Account Registration and Requirements</h3>
            <ul style={{ listStyle: 'none' }}>
                <li>
                    <b>6.1 Sign Up</b>
                    <div>
                        <ul style={{ listStyle: 'none' }}>
                            <li className="d-flex">
                                <span className="me-3">6.1.1</span>
                                <p>	Prior to joining and playing the Cryptopolis Game on the Website, you will be required to connect your Digital Wallet, and putting your e-mail address into the Platform’s system.</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">6.1.2</span>
                                <p>	When the Game launches, you will then be required to register and create your Cryptopolis Account by using the same wallet address, and e-amil address which are initially used to sign up.</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">6.1.3</span>
                                <div>
                                    <p>To play the Game, you need to have a nominal amount of BNB cryptocurrency to pay for transactional gas fees, and you should have $CPO Tokens in your Digital Wallet.</p>
                                    <p>However, you do not need to own $CPO Tokens in order to start the Game since you can still play the Game under the Play - to - Earn system which allows you to collect NFT(s) without having to use any of your funds. Once you decide to purchase $CPO Tokens from the Website, such $CPO Tokens will be used to purchase the upgraded or higher value NFT(s) and quickly make progress through the higher levels of the GAME.</p>
                                </div>
                            </li>
                        </ul>
                        <p>When you initially sign up with CRYPTOPOLIS, you hereby agree to provide complete and accurate information which includes your username, e-mail address, and other necessary information for the verification. You further agree to periodically update your information provided to CRYPTOPOLIS for accessing and entering into the Cryptopolis Platform and the Game. However, at our sole discretion and without liability to you, CRYPTOPOLIS may refuse you to sign up and open your Cryptopolis Account if your information has been verified to be outdated, incomplete, or incorrect.</p>
                        <p>CRYPTOPOLIS reserves the right to change or modify any signing up criteria or conditions, at our own discretion, without noticing to you.</p>
                    </div>
                </li>
                <li>
                    <b>6.2 Eligibility</b>
                    <div>
                        <ul style={{ listStyle: 'none' }}>
                            <li className="d-flex">
                                <span className="me-3">6.2.1</span>
                                <p>You are at least 18 years old and have full legal capacity, or you are at least the age of majority in your state, jurisdiction, or resident country, or you are the age of majority in your state, jurisdiction, or resident country and you have given CRYPTOPOLIS your consent to allow any of your minor dependent to use the Cryptopolis Platform and the Game.</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">6.2.2</span>
                                <p>You are not a citizen or a resident from the Prohibited Jurisdictions, and you do not have any relevant connection with any jurisdictions where we have prohibited the Game, and the accessibility to the Cryptopolis Platform.</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">6.2.3</span>
                                <p>Your accessing, use, participation, or purchasing any in - game objects of the Game and any NFT items will not violate any Applicable Laws or regulations to you, including but not limited to regulations of the AML/CFT.</p>
                            </li>
                        </ul>
                        <p>By signing up for an opening the Cryptopolis Account, you represent and warrant that the following qualifications are met:</p>
                    </div>
                </li>
                <li>
                    <b>6.3 Account Compliance with the laws</b>
                    <p>CRYPTOPOLIS regularly monitors and assesses activities that may constitute a potential breach of the AML/CFT laws, Sanctions laws, Anti-Bribery laws, and other relevant Applicable Laws and/or international regulations. We will collect, use, and maintain information provided by you following these Terms and our Privacy Policy. You also authorize us to conduct investigations and agree to our collection of additional information, either directly or through regulated third-party service providers to ensure our compliance with international regulations and laws relating to the operation of CRYPTOPOLIS and in order to protect you and us from any possible financial crimes, and to take necessary actions based on the results of such investigations.</p>
                    <p>You fully acknowledge that your information may be disclosed to government agencies for fraud and financial crime prevention upon a request by a subpoena and/or a valid court order. Once you have registered your Cryptopolis Account, you must ensure that all information provided to CRYPTOPOLIS is complete, accurate, and update in a timely manner when there is any change to the information held by CRYPTOPOLIS in relation to your Account. Should there be any reasonable grounds to believe that the partial or the whole of your information provided to us is incomplete, or incorrect, or outdated, CRYPTOPOLIS reserves the right to send you a notice to demand correction, or to delete such information directly, and, as the case may be, to terminate/suspend/freeze the access to all or part of the Cryptopolis Platform and the Game that we provide to you. If CRYPTOPOLIS cannot reach you with the contact information you have provided to us, you will be fully liable for any loss, damage, or expenses caused to CRYPTOPOLIS.</p>
                </li>
                <li>
                    <b>6.4 Account Usage</b>
                    <p>The Cryptopolis Account will be used by an account registrant and authorized persons only. In this regard, CRYPTOPOLIS reserves the right to suspend, freeze, or cancel the access or use of the Cryptopolis Platform and the Game by any other persons other than the account registrant. If you suspect or become aware of any unauthorized access to your Account or use of your username or wallet address or any other violation of security rules, you will notify CRYPTOPOLIS immediately. CRYPTOPOLIS will have no liability for any loss, damage, or any consequence  arising from the access or use of the Cryptopolis Account by you or any third party with or without your authorization. </p>
                </li>
                <li>
                    <b>6.5 Prohibited Uses</b>
                    <p>CRYPTOPOLIS maintains a policy of strict regulatory compliance. In addition to other prohibitions as set forth in these Terms, you are prohibited from using the Cryptopolis Platform and the Game or its content for the following purposes:</p>
                    <ul style={{ listStyle: 'none' }}>
                        <li className="d-flex">
                            <span className="me-3">6.5.1</span>
                            <p>for unlawful purpose or be involved in any darknet marketplaces;</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">6.5.2</span>
                            <p>to solicit others to commit or participate in any unlawful acts;</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">6.5.3</span>
                            <p>to infringe upon or violate our intellectual property rights or the intellectual 	property rights of others;</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">6.5.4</span>
                            <p>to submit false or misleading information;</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">6.5.5</span>
                            <p>to upload or transmit viruses or any other type of malicious code that will or may 	be used in any way that will affect the functionality or operation of the Platform 	and the Game;</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">6.5.6</span>
                            <p>to collect or track the personal information of other users;</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">6.5.7</span>
                            <p>to spam, phish, pharm, pretext, spider, crawl, or scrape;</p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">6.5.8</span>
                            <p>for any obscene or immoral purpose; or </p>
                        </li>
                        <li className="d-flex">
                            <span className="me-3">6.5.9</span>
                            <p>to interfere with or circumvent the security features of the Platform and the 	Game or any related website.</p>
                        </li>
                    </ul>
                    <p>CRYPTOPOLIS retains the right to freeze or terminate your Cryptopolis Account as necessary for violating any of the prohibited uses including the use of proxy servers. Any attempt to circumvent these prohibited uses will also result in a similar action.</p>
                </li>
                <li>
                    <b>6.6 Account Security</b>
                    <div>
                        <p>CRYPTOPOLIS has been committed to maintaining the security of your Account and has implemented industry-standard protection for the Platform and the Game. However, the actions of individual Users may pose risks. You hereby agree to treat your access credentials (such as your username, password, and wallet address) as confidential information, and not to disclose such information to any third party. You further agree to be solely responsible for taking the necessary security measures to protect your Account and personal information.</p>
                        <p>You hereby acknowledge and agree that CRYPTOPOLIS assumes no liability for any loss, consequence, or damage caused by authorized or unauthorized use of your account credentials, including but not limited to information disclosure, information release, consent or submission of various rules and agreements by clicking on or entering the Platform, and any online agreement renewal, etc.</p>
                    </div>
                </li>
            </ul>
        </div>

        <div className="mt-5">
            <h3>7. How Does the Cryptopolis Game Work</h3>
            <ul style={{ listStyle: 'none' }}>
                <li className="d-flex">
                    <span className="me-3">7.1</span>
                    <p>You will sign up and create the Cryptopolis Account to access to the Game, to purchase or earn $CPO Tokens which will be used for playing the Game, and NFT(s) trade on NFT marketplace.</p>
                </li>
                <li className="d-flex">
                    <span className="me-3">7.2</span>
                    <p>After the Account registration, you will get your first free small room situated in the cellars of the Cryptopolis tower. You can buy the bigger rooms which are situated on the higher floors of the Cryptopolis tower by using $CPO Tokens.</p>
                </li>
                <li className="d-flex">
                    <span className="me-3">7.3</span>
                    <p>In order to start the Game and earn more $CPO Tokens, you will participate in the challenges and three skill-based minigames where you can wager any amount of $CPO against your friends or strangers and then you gain “XPerience” or “XP”.</p>
                </li>
                <li className="d-flex">
                    <span className="me-3">7.4</span>
                    <div>
                        <p>Accumulating XP will offer you opportunities to bid on the higher value NFT(s) and buy the bigger rooms on the higher floor of the Cryptopolis tower. Your NFT(s) are stored in the blockchain, and CRYPTOPOLIS has no control and ownership over your NFT(s) once it is purchased by you or other players. </p>
                        <p>You can bid, purchase or sell your NFT items on the NFT marketplace. Once you buy an NFT, you have an option to sell it to other players by displaying your NFT in your room. Other players who are interested in your NFT will enter your room, and put an offer in. You will then receive a notification that someone has offered you some amount of $CPO Tokens to buy your NFT.</p>
                    </div>
                </li>
            </ul>
        </div>

        <div className="mt-5">
            <h3>8. $CPO Token</h3>
            <ul style={{ listStyle: 'none' }}>
                <li className="d-flex">
                    <span className="me-3">8.1</span>
                    <p>CRYPTOPOLIS will issue a non-currency called “$CPO Token” which is classified as a Utility Token designed to be used solely on the Cryptopolis Platform, and the Game.</p>
                </li>
                <li className="d-flex">
                    <span className="me-3">8.2</span>
                    <p>$CPO Token is neither money nor legal tender/currency, whether fiat or otherwise, and it does not carry any value whether it is intrinsic or assigned.</p>
                </li>
            </ul>
        </div>

        <div className="mt-5">
            <h3>9. Misuse of the Website</h3>
            <ul style={{ listStyle: 'none' }}>
                <li className="d-flex">
                    <span className="me-3">9.1</span>
                    <p>In the event of any misuse and/or abuse of the Website or breach any provision in these Terms, we reserve the right to close or block your Account until the matter is solved.</p>
                </li>
                <li className="d-flex">
                    <span className="me-3">9.2</span>
                    <p>Use of the Website for transmission, publication or storage of any material on or via the Website which is in violation of any applicable laws or regulations or any third-party’s rights is strictly prohibited, including but not limited to the use of the Website or the transmission, distribution, publication or storage any material on or via the Website in a matter or for the purpose which infringes copyright, trademark, trade secret or other intellectual property rights, is obscene or harmful to minors or constitutes an illegal act or harassment, is libellous or defamatory, violates any privacy or data protections laws, is fraudulent or breaches any exchange control laws. </p>
                </li>
                <li className="d-flex">
                    <span className="me-3">9.3</span>
                    <p>If, in our sole discretion, we find that you have cheated or attempted to defraud us or any other Users of the Game in any way including but not limited to game manipulation or fraud, or if we suspect you of fraudulent activity or prohibited transaction or money laundering transactions, we reserve the right to suspend and/or close Account and may share this fraud information to other appropriate third parties upon the subpoena or the valid court order.</p>
                </li>
            </ul>
        </div>

        <div className="mt-5">
            <h3>10. Representations and Warranties</h3>
            <ul style={{ listStyle: 'none' }}>
                <li className="d-flex">
                    <span className="me-3">10.1</span>
                    <div>
                        <p>You hereby agree to make the following representations and warranties to CRYPTOPOLIS:</p>
                        <ul className="ps-0" style={{ listStyle: 'none' }}>
                            <li className="d-flex">
                                <span className="me-3">10.1.1</span>
                                <p>You are the exclusive owner of any cryptocurrencies, tokens, including BNB and your $CPO Tokens, NFT(s) items held in your Digital Wallet.</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">10.1.2</span>
                                <p>CRYPTOPOLIS will not be responsible for actions taken by you that result in the loss or destruction of the value of the cryptocurrencies, tokens, and NFT(s) you hold.</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">10.1.3</span>
                                <p>You validly undertake any action or enter into any transaction with regard to these 	Terms;</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">10.1.4</span>
                                <p>The cryptocurrencies, and tokens maintained in your Digital Wallet will not be derived from any acts associated with money laundering, terrorist financing, fraud, or any other illegal activities under any applicable laws;</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">10.1.5</span>
                                <p>You will understand and be aware of risks associated with accessing or using or participating in the Cryptopolis Platform and the Game, and you will be fully liable at your own risk;</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">10.1.6</span>
                                <p>You will participate, or use any services, or play the Game via the Platform in good faith and will not use it for the purpose of concealing or disguising the origin or nature of the proceeds derived from illegal or criminal activities;</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">10.1.7</span>
                                <p>You will be aware that you are subject to tax regulations in the jurisdiction you reside in and will be fully responsible for filling or reporting any taxes and paying them as required by the Applicable Laws. CRYPTOPOLIS will not be liable to compensate you for your tax obligations or advise you in relation to your tax obligations. Any uncertainties and unpredictable matters in tax legislation with respect to any tokens may expose you to any unknown or unforeseeable tax implications associated with your holding of tokens and the use or the participation in the Game for which CRYPTOPOLIS will have no liability. Moreover, you will not hold CRYPTOPOLIS liable for any expense or loss resulting from unknown or unforeseeable tax implications;</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">10.1.8</span>
                                <p>You will not breach any of the provisions stipulated in these Terms, the Privacy Policy, or any Applicable Laws in any relevant jurisdictions;</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">10.1.9</span>
                                <p>You will not interfere, intercept, or expropriate our network system, data, or information;</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">10.1.10</span>
                                <p>You will not transmit or upload any viruses or other malicious software program, or try to gain unauthorized access to other accounts, Websites, networks or systems affiliated to the Cryptopolis Platform and the Game;</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">10.1.11</span>
                                <p>You will not decompile, reverse engineer, or disassemble any of our programs, systems or products, or in any way infringe our Intellectual Property rights;</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">10.1.12</span>
                                <p>You will not cause or launch any programs or scripts for the purposes of scraping, indexing, surveying, or otherwise data mining any portion of any of our programs, systems or products.</p>
                            </li>
                        </ul>
                    </div>
                </li>
                <li className="d-flex">
                    <span className="me-3">10.2</span>
                    <p>You will defend, indemnify, and not hold CRYPTOPOLIS, its affiliates, each of their respective employees, officers, directors, and representatives liable to and against any claims, damages, losses, liabilities, costs, and expenses (including reasonable attorney's fees), arising out of or relating to any third-party claim concerning these Terms, or your use of the Cryptopolis Platform in violation of these Terms and Applicable laws.</p>
                </li>
                <li className="d-flex">
                    <span className="me-3">10.3</span>
                    <p>You acknowledge and agree that if you lose access to your Digital Wallet that you signed up and connect with the Game, CRYPTOPOLIS will not be able to transfer $CPO Tokens and NFT items to any new Account or be able to help you recover such loss. It will be your solely responsibility to manage your Account, your Digital Wallet, and your private key. </p>
                </li>
                <li className="d-flex">
                    <span className="me-3">10.4</span>
                    <p>Each of the Representations and Warranties will survive and continue to have full force and effect after the execution of these Terms.</p>
                </li>
                <li className="d-flex">
                    <span className="me-3">10.5</span>
                    <p>CRYPTOPOLIS makes no representation, warranty, or guarantee to you of any kind. The Cryptopolis Platform, and the Game are offered strictly on an as-is and as-available basis and, without limiting the generality of the foregoing, are offered without any representation as to its merchantability or fitness for any particular purpose.</p>
                </li>
            </ul>
        </div>

        <div className="mt-5">
            <h3>12. Risk Disclosure</h3>
            <p>By accessing or using or participating in the Cryptopolis Platform and the Game, you expressly acknowledge and assume the following risks:</p>
            <ul style={{ listStyle: 'none' }}>
                <li>
                    <b className="me-3">12.1 Risk of loss in value</b>
                    <p>Tokens or any cryptocurrencies are not issued by any central banks or national, supra-national, or quasi-national organizations. They are also not backed by any hard assets or other credit. The value of tokens or any cryptocurrencies is affected by several factors, including but not limited to, the total number of tokens or any cryptocurrencies in existence, the continued willingness of market participants to exchange government-issued currency for tokens or cryptocurrencies, purchasers' expectations with respect to the rate of inflation of fiat currencies, purchasers' expectations with respect to the rate of deflation of cryptocurrencies, interest rates, currency exchange rates, cyber theft of cryptocurrencies from online digital wallet providers, or news of such theft from such providers or individuals' digital wallets, investment and trading activities of large investors, monetary policies of the governments, trade restrictions, currency devaluations and revaluations, regulatory measures, the global or regional political, economic or financial events and situations. Thus, all these factors will affect the value of tokens or cryptocurrencies, which may result in the permanent partial or total loss of the value of a particular token or cryptocurrencies. No one will be obliged to guarantee the liquidity or the market price of any of the tokens or cryptocurrencies maintained into your Digital Wallet on the Cryptopolis Account.</p>
                </li>
                <li>
                    <b className="me-3">12.2 The regulatory regime governing tokens or cryptocurrencies</b>
                    <div>
                        <p>The regulatory framework relating to tokens or cryptocurrencies remain unsettled, and any laws, regulations, or guidelines may be significantly revised and amended which will materially and adversely affect the value of tokens or cryptocurrencies and your services and the Game on the Platform as follows.</p>
                        <ul style={{ listStyle: 'none' }}>
                            <li className="d-flex">
                                <span className="me-3">12.2.1</span>
                                <p>Regulations of the use, transfer, and exchange of tokens or cryptocurrencies in various jurisdictions are currently undeveloped or underdeveloped and likely to evolve rapidly. One or more countries may take regulatory actions in the future that severely restricted, which may directly or indirectly affect or restrict CRYPTOPOLIS’s services, and the Game provided to Users.</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">12.2.2</span>
                                <p>To the extent that CRYPTOPOLIS may be required to obtain licenses, permits, and/or approvals in any jurisdiction to offer the Game, but are unable to obtain such Regulatory Approvals or if such Regulatory Approvals are not renewed or revoked for any reasons, the Users in such jurisdictions will be unable to access or use or purchase the Game and its NFT items on the Platform.</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">12.2.3</span>
                                <p>It is difficult to predict how or whether governments or regulatory authorities may implement any changes to laws and regulations affecting the use, transfer, and exchange of tokens or digital currencies. In the case where CRYPTOPOLIS may be requested or forced to suspend or discontinue or to change the operation of CRYPTOPOLIS’s services in any jurisdictions required by laws and the authorities, your Cryptopolis Account may be frozen for an indefinite period of time until the matter is solved. You will be responsible for determining whether the use of CRYPTOPOLIS’s services or the Game is legal in your jurisdiction, and you will not access or use CRYPTOPOLIS’s services and the Game if they are illegal in your jurisdiction. If you are uncertain on the legal status of tokens or digital currencies in your jurisdiction, please seek independent advice from your legal advisor.</p>
                            </li>
                        </ul>
                    </div>
                </li>
                <li className="d-flex">
                    <span className="me-3">12.3</span>
                    <div>
                        <p>Technical and System Failure affected the obligations stipulated in these Terms</p>
                        <p>CRYPTOPOLIS may experience system failures, unplanned interruptions in its network or services and products, hardware or software defects, security breaches or other causes that could adversely affect the CRYPTOPOLIS‘s infrastructure network, which includes the Website and the Game.</p>
                        <p>CRYPTOPOLIS is unable to anticipate the occurrence of hacks, cyber-attacks, mining attacks, including but not limited to double-spend attacks, majority mining power attacks and selfish-mining attacks, distributed denial of service attacks or errors, vulnerabilities or defects in the Website, $CPO Tokens, Users' Accounts, Users' digital wallets or any technology, including but not limited to Binance Smart Chain Network, and other smart contract technology. Also, CRYPTOPOLIS is unable to detect the hacks as mentioned earlier, mining attacks, cyber-attacks, distributed denials of service errors vulnerabilities, or defects in a timely manner and does not have sufficient resources to efficiently cope with multiple service incidents happening simultaneously or in rapid succession. </p>
                        <p>In addition, CRYPTOPOLIS’s services and the Game could be disrupted by numerous events, including natural disasters, equipment breakdown, network connectivity downtime, power losses, or even intentional disruptions of its services and products, such as disruptions caused by software viruses or attacks by unauthorized users, some of which are beyond CRYPTOPOLIS's control. Although CRYPTOPOLIS has taken steps against malicious attacks on its appliances or its infrastructure, which are critical for the maintenance of the Cryptopolis Platform and the Game, there can be no assurance that cyber-attacks, such as distributed denials of service, will not be attempted in the future, and that CRYPTOPOLIS's enhanced security measures will be effective. Any significant breach of CRYPTOPOLIS's security measures or other disruptions resulting in a compromise of the usability, stability and security of CRYPTOPOLIS's network or services and products, including the Platform, may adversely affect $CPO Tokens.</p>
                    </div>
                </li>
                <li className="d-flex">
                    <span className="me-3">12.4</span>
                    <div>
                        <p>CRYPTOPOLIS will have no liability for any delay, error, interruption, or failure to perform any obligation under these Terms where the delay or failure is directly or indirectly resulting from any causes beyond CRYPTOPOLIS's control, including, but not limited to;</p>
                        <ul style={{ listStyle: 'none' }}>
                            <li className="d-flex">
                                <span className="me-3">12.4.1</span>
                                <p>Acts of God, nature, or court of government;</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">12.4.2</span>
                                <p>Failure or interruption of public or private telecommunication networks, blockchain network, communication channels or information systems;</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">12.4.3</span>
                                <p>Acts or omission of acts of a party for whom we are not responsible;</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">12.4.4</span>
                                <p>Delay, failure, or interruption in, or unavailability of, third-party services and products;</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">12.4.5</span>
                                <p>Strikes, lockouts, labour disputes, wars, terrorist acts and riots.</p>
                            </li>
                        </ul>
                    </div>
                </li>
                <li className="d-flex">
                    <span className="me-3">12.5</span>
                    <p>YOU UNDERSTAND AND AGREE THAT YOUR PARTICIPATION AND USE OF CRYPTOPOLIS PLATFORM AND THE GAME IS COMPLETELY AT YOUR OWN RISK. THIS CLAUSE IS NOT EXHAUSTIVE AND DOES NOT DISCLOSE ALL THE RISKS ASSOCIATED WITH DIGITAL CURRENCIES AND THE USE OF THE PLATFORM AND THE GAME. THEREFORE, YOU ARE RECOMMENDED TO CAREFULLY CONSIDER WHETHER SUCH USE IS SUITABLE FOR YOU IN LIGHT OF YOUR JUDGEMENT, CIRCUMSTANCES, AND FINANCIAL POSITION.</p>
                </li>
            </ul>
        </div>

        <div className="mt-5">
            <h3>13. Limitation of Liability</h3>
            <ul style={{ listStyle: 'none' }}>
                <li className="d-flex">
                    <span className="me-3">13.1</span>
                    <p>NOTWITHSTANDING ANY PROVISIONS WITHIN THESE TERMS, IN NO EVENT WILL CRYPTOPOLIS, ITS AFFILIATES, OR ITS EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE TO THE USER FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, INDIRECT, OR CONSEQUENTIAL DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR OTHER SUCH WEBSITES, OR ANY OTHER SERVICES AND THE GAME OR ITEMS OBTAINED THROUGH THE WEBSITE, OR OTHER SUCH WEBSITES, INCLUDING, BUT NOT LIMITED TO, LOST REVENUE, LOST PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, LOSS OF YOUR CREDENTIAL INFORMATION (USERNAME AND WALLET ADDRESS), REPLACEMENT GOODS, LOSS OR INTERRUPTION OF TECHNOLOGY, LOSS OF USE OF SERVICE OR EQUIPMENT, EVEN IF THE USER WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND WHETHER ARISING UNDER A THEORY OF CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE. CRYPTOPOLIS WILL ALSO HAVE NO LIABILITY FOR ANY EMERGENCY AND/OR UNFORESEEABLE INCIDENTS RELATED TO YOUR USE AND PARTICIPATION OF CRYPTOPOLIS PLATFORM AND THE GAMSE SUCH AS UNAUTHORISED ACCESS, STOLEN USERNAME, STOLEN PRIVATE KEY OR WALLET ADDRESS, OR HACKED ACCOUNTS.</p>
                </li>
                <li className="d-flex">
                    <span className="me-3">13.2</span>
                    <div>
                        <p>Except as expressly provided in these Terms, to the maximum extent permitted by any Applicable Laws, we disclaim all other representations or warranties, express or implied, made to you, your affiliates, or any other person, including, without limitation, any warranties regarding the quality, suitability, merchantability, fitness for a particular purpose or otherwise (regardless of any course of dealing, custom or usage of trade) of any service or any goods provided incidental to the Game under these Terms.</p>
                        <p>In no event will our aggregate liability for any loss or damage that arises in connection with the Game exceed the total purchase amount you paid to participate in the Game on the Cryptopolis Platform.</p>
                    </div>
                </li>
                <li className="d-flex">
                    <span className="me-3">13.3</span>
                    <div>
                        <p>CRYPTOPOLIS will have no liability for any third-party services and products provided or made available to Users.</p>
                        <p>CRYPTOPOLIS makes no representations or warranties whatsoever, expressed or implied, as to the accuracy, totality, timeliness, appropriateness, suitability, or vigour for any purpose or use of such third-party services and products, products, services and products and information, customer and technical support or web presence or any of their presentations or representations. You will acknowledge and agree that CRYPTOPOLIS will not be liable in any way for the use of such third-party services and products.</p>
                    </div>
                </li>
            </ul>
        </div>

        <div className="mt-5">
            <h3>14. Indemnification</h3>
            <ul style={{ listStyle: 'none' }}>
                <li className="d-flex">
                    <span className="me-3">14.1</span>
                    <div>
                        <p>You irrevocably undertake the responsibility of fully indemnifying and holding harmless each of CRYPTOPOLIS, its affiliates, shareholders, officers, directors, employees, and agents and their respective successors, assigns promptly upon demand at any time and from time to time, from and against any and all losses, claims, actions, proceedings, damages, demands, judgements, sums, liabilities, damages, costs, charges and expenses, including, but not limited to, any reasonable attorney's fees or penalties imposed by any regulatory authority, and reimbursements arising out of or related to the following situations:</p>
                        <ul style={{ listStyle: 'none' }}>
                            <li className="d-flex">
                                <span className="me-3">14.1.1</span>
                                <p>Your use or participation in accordance with CRYPTOPOLIS’s services and the Game on the Cryptopolis Platform;</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">14.1.2</span>
                                <p>Your breach of or our enforcement of these Terms;</p>
                            </li>
                            <li className="d-flex">
                                <span className="me-3">14.1.3</span>
                                <p>Any violations of Applicable Laws, regulation, or rights of any third-party during your use or participate in the Cryptopolis Platform and the Game.</p>
                            </li>
                        </ul>
                        <p>If you are obligated to indemnify CRYPTOPOLIS, its affiliates, shareholders, officers, directors, employees, and agents and their respective successors, CRYPTOPOLIS will have the right, at our sole discretion, to control any action or proceeding and to determine whether CRYPTOPOLIS wishes to proceed, or settle, and if so, on what terms or provisions.</p>
                    </div>
                </li>
            </ul>
        </div>

        <div className="mt-5">
            <h3>15. Termination</h3>
            <ul style={{ listStyle: 'none' }}>
                <li className="d-flex">
                    <span className="me-3">15.1</span>
                    <p>These Terms are effective unless and until terminated by either you or us.</p>
                </li>
                <li className="d-flex">
                    <span className="me-3">15.2</span>
                    <p>You may terminate these Terms at any time by notifying us that you no longer wish to use or participate in the Cryptopolis Platform and the Game, or when you choose to immediately cease or discontinue your Cryptopolis Account.</p>
                </li>
                <li className="d-flex">
                    <span className="me-3">15.3</span>
                    <p>These Terms can be terminated at any time by us, without a notice, if there is a reasonable ground to believe that you have breached any of the terms or provisions stipulated in these Terms, or if you do not comply with these Terms, and you will remain liable for all amounts sue up to and including the date of termination.</p>
                </li>
                <li className="d-flex">
                    <span className="me-3">15.4</span>
                    <p>The termination of these Terms will not prevent CRYPTOPOLIS from seeking remedies from you in the case where you breach any terms or provisions before such termination.</p>
                </li>
                <li className="d-flex">
                    <span className="me-3">15.5</span>
                    <p>Any ongoing obligation to you as well as the provisions regarding (i) CRYPTOPOLIS's  Intellectual Property, (ii) No solicitation or Offering, (iii) indemnification, (iv) Limitation of liability, and (v) any other provisions designed to survive, will survive any termination or expiration of these Terms for any reason.</p>
                </li>
            </ul>
        </div>

        <div className="mt-5">
            <h3>16. Notice/Announcement</h3>
            <p>Any notice required or made under these Terms from CRYPTOPOLIS to you will be considered validly received when addressed to the latest contact information you have provided, including, but not limited to, the email address last used, mailing addresses. Additionally, CRYPTOPOLIS may provide notices by publishing on the Website or on other social media channels. Any notice required or made by you to CRYPTOPOLIS will only be made through our social media accounts such as Twitter, Telegram.</p>
        </div>

        <div className="mt-5">
            <h3>17. No Financial and Legal Advice</h3>
            <p>CRYPTOPOLIS is not your broker, intermediary, agent, or legal advisor and has no fiduciary relationship or obligation to you in connection with any decisions or activities effected by you using or participating in the Platform. No communication or information provided to you by CRYPTOPOLIS is intended as or will be considered or construed as, investment advice, financial advice, legal advice, or any other sort of advice. The Cryptopolis Game, its NFT items, and any transactions will be executed automatically based on the parameters of your consideration. You will be solely responsible for determining whether any game, its services, or any investment are suitable and match your interests according to your judgement, objectives, circumstances, and risk tolerance. You will be solely responsible for any loss, damage, or liability therefrom.</p>
            <p>Before executing any transactions, earning any $CPO Tokens, and purchasing any NFT items in the Game, you should consult with your independent financial, legal, or tax professionals. CRYPTOPOLIS will not be liable for the decisions you make to access the Platform. </p>
        </div>

        <div className="mt-5">
            <h3>18. Governing Law, Resolving Disputes, Arbitration and Class Action Waiver</h3>
            <p>PLEASE READ THIS SECTION CAREFULLY AS IT INVOLVES A WAIVER OF CERTAIN RIGHTS TO BRING LEGAL PROCEEDINGS, INCLUDING A CLASS ACTION.</p>
            <ul style={{ listStyle: 'none' }}>
                <li>
                    <b>18.1 Notice of Claim and Dispute Resolution Period</b>
                    <p>Please contact CRYPTOPOLIS first. CRYPTOPOLIS seeks to address your concerns without resorting to formal legal proceedings whenever possible. If you have a dispute with CRYPTOPOLIS, you should contact CRYPTOPOLIS, and a case number will be assigned. CRYPTOPOLIS will attempt to resolve your dispute internally as soon as possible. The parties will agree to negotiate in good faith to resolve the dispute and discussions will remain confidential and subject to applicable laws protecting settlement discussions from use as evidence in any legal proceeding.</p>
                </li>
                <li>
                    <b>18.2 Agreement to Arbitrate</b>
                    <p>You and CRYPTOPOLIS agree that subject to paragraph 18.1 above, any dispute, claim, or controversy between you and CRYPTOPOLIS that arises in connection with, or relating in any way, to these Terms, or to your relationship with CRYPTOPOLIS as a user of CRYPTOPOLIS’s services and  the Game (whether by contract, tort, statute, fraud, misrepresentation, or any other legal theory, and whether the claims arise during or after the termination of these Terms) will be determined by a mandatory final and binding individual arbitration rather than a class action, except as set forth below under Exceptions to the Agreement to Arbitrate. You and CRYPTOPOLIS further agree that the arbitrator will have the exclusive power to rule on his or her jurisdiction, including, without limitation, any objections with respect to the existence, scope, or validity of the Agreement to Arbitrate, or to the arbitrability of any claim or counterclaim. Arbitration is more informal than a lawsuit in court. THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED. There may be more limited discovery than in court. The arbitrator must follow this agreement and can award the same damages and relief as a court, including, if applicable, attorney fees, except the arbitrator may not award declaratory or injunctive relief benefiting anyone but the parties to the arbitration. The arbitration provisions set forth in this Section will survive termination of these Terms.</p>
                    <ul style={{ listStyle: 'none' }}>
                        <li>
                            <b>18.2.1 Arbitration Rules</b>
                            <p>Any dispute arising out of or in connection with these Terms, including any question regarding its exercise, validity, or termination, will be referred to and finally resolved by arbitration in Singapore in accordance with the Arbitration Rules of the Singapore International Arbitration Centre (“SIAC Rules”) for the time being in force, which rules are deemed to be incorporated by reference in this Clause.<br />
                                The Tribunal will consist of [1/or3] arbitrator(s).<br />
                                The language of the arbitrator will be English.</p>
                        </li>
                        <li>
                            <b>18.2.2 Governing Law/Jurisdiction</b>
                            <p>The governing law of the arbitration will be that of the Republic of Seychelles.</p>
                        </li>
                        <li>
                            <b>18.2.3  Confidentiality.</b>
                            <p>The parties agree that the arbitration will be kept confidential. The existence of the arbitration, any non-public information provided in the arbitration, and any submissions, orders or awards made in the arbitration (alongside the "Confidential Information") will not be disclosed to any non-parties except the tribunal, the parties, their counsel, experts, witnesses, accountants and auditors, insurers and reinsurers, and any other person necessary to facilitate the arbitration. Notwithstanding the preceding, a party may disclose Confidential Information to the extent that disclosure may be required to fulfil a legal duty, protect, or pursue a legal right, or enforce or challenge an award in bona fide legal proceedings. This confidentiality provision will survive the termination of these Terms and any arbitration brought under these Terms.</p>
                        </li>
                    </ul>
                </li>
                <li>
                    <b>18.3 Class Action Waiver</b>
                    <p>You and CRYPTOPOLIS agree that any claims relevant to these Terms, or your relationship with CRYPTOPOLIS will be brought against the other party in arbitration on an individual basis only and not as a plaintiff or class member in a purported class or representative action. You and CRYPTOPOLIS further agree to waive any right for such claims to be brought, heard, or arbitrated as a class, collective, representative, or private attorney general action, to the extent permissible by applicable laws. Combining or consolidating individual arbitrations into a single arbitration is not permitted without the consent of all parties involved. </p>
                </li>
                <li>
                    <b>18.4 Modifications</b>
                    <p>CRYPTOPOLIS reserves the right to update, modify, revise, suspend, or make future changes to Clause 18.2 regarding the parties' Agreement to Arbitrate, subject to applicable laws. You hereby consent and agree that it is your responsibility to ensure that your understanding of this Clause is up to date. Subject to applicable laws, your continued use of your Cryptopolis Account will be interpreted as your acceptance of any modifications to Clause 18 regarding the parties' Agreement to Arbitrate. You agree that if you object to the modifications to Clause 18, CRYPTOPOLIS may block access to your account pending closure of your account. In such circumstances, these Terms prior to modification will remain in full force and affect the pending closure of your account.</p>
                </li>
            </ul>
        </div>

        <div className="mt-5">
            <h3>19. Miscellaneous</h3>
            <ul style={{ listStyle: 'none' }}>
                <li>
                    <b>19.1 Severability</b>
                    <p>If any of the provisions in these Terms is found by a court of competent authority to be invalid, void, unlawful or unenforceable under any applicable laws, such unenforceability or invalidity will not render these Terms unenforceable or invalid as a whole, and such provisions will be deleted without affecting the remaining provisions herein.</p>
                </li>
                <li>
                    <b>19.2 Modification of any provision in these Terms</b>
                    <p>CRYPTOPOLIS has the right to revise these Terms at our sole discretion at any time, and by using the Platform and the Game, you will be expected to review such Terms regularly to ensure that you understand all provisions stipulated in these Terms.</p>
                </li>
                <li>
                    <b>19.3 Assignment</b>
                    <p>CRYPTOPOLIS will be allowed to assign, transfer, and subcontract Our rights and/or obligations under these Terms without the need to provide you any notification or acquire your consent. Nevertheless, you will not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>
                </li>
                <li>
                    <b>19.4 Entire Agreement</b>
                    <p>These Terms, including the Privacy Policy and any rules contained on the Website or relevant social media channels, constitute the sole and entire agreement between you and CRYPTOPOLIS with respect to your access and use of Cryptopolis Platform and the Game, and supersedes other prior or contemporaneous negotiations, discussions, agreements, understandings, representations, and warranties, both written and oral, between you and CRYPTOPOLIS with respect to such subject matter.</p>
                </li>
                <li>
                    <b>19.5 No Third-Party Rights</b>
                    <p>Nothing in these Terms will be deemed to create any rights to any creditors or other persons, not a party hereto. Moreover, these Terms will not be construed, in any respect, to be a contract, in whole or in part, for the benefit of any third parties.</p>
                </li>
                <li>
                    <b>19.6 Clickwrap</b>
                    <p>CRYPTOPOLIS will deliver the Cryptopolis Game and its relevant gaming items through electronic means such as download links, graphical, tools or other technologies for providing the Game and its items for Users. The User interfaces to such electronic means may require that the Users agree to these Terms by checking a box, clicking a button, or continuing with the Game and its services on the Platform. Such an action of acceptance shall be sufficient to bind the Users to the provisions herein these Terms. </p>
                </li>
                <li>
                    <b>19.7 Waiver</b>
                    <p>The failure of one Party to require the performance of any provision will not affect that Party's right to require performance at any time thereafter. At the same time, the waiver of one Party to seek recovery for the other Party's violation of these Terms of any provisions of applicable terms will not constitute a waiver by that Party of any subsequent breach or violation by the other Party or of the provision itself.</p>
                </li>
            </ul>
        </div>
    </div>
}
export default TermOfUsePage;