import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery';
import { get } from "lodash";
import { Link } from "react-router-dom";
import { ACTION_CONST, ROUTES, STATUS } from "../../shared/constants";
import { BSC_EXPLORER, MODE } from "../../_configs";
import { Button } from "react-bootstrap";
import { BigNumber } from "bignumber.js";
import { helpers } from "../../shared/utils";
import StakingPortalPage from "../../shared/layout/StakingPortal";
import { toast } from "react-toastify";

const UnStakingPage = () => {
    const dispatch = useDispatch();
    const [unStakeCurrentStep, setUnStakeCurrentStep] = useState(1);
    const isConnectWallet = useSelector((state) => get(state, "utils.isConnectWallet", false));
    const walletUtils = useSelector((state) => get(state, "utils.walletUtils", null));
    const stakingInfo = useSelector((state) => get(state, "wallet.stakingInfo", {}));
    const stakingWalletInfo = useSelector((state) => get(state, "wallet.stakingWalletInfo", {}));
    const bnbBalance = useSelector((state) => get(state, "wallet.bnbBalance", 0));
    const [acceptTerm, setAcceptTerm] = useState(false);
    const [enableUnStakeBtn, setEnableUnStakeBtn] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");
    const [unStakeAmount, setUnStakeAmount] = useState('0');
    const [isMaxAmount, setIsMaxAmount] = useState(false);
    const [isSubmitOK, setIsSubmitOK] = useState(false);

    useEffect(() => {
        setUnStakeStepActive();
    }, [unStakeCurrentStep])

    //enable unstake button
    useEffect(() => {
        if (unStakeCurrentStep === 2) {
            if (acceptTerm &&
                isConnectWallet &&
                !stakingInfo["isStakingPaused"] &&
                parseFloat(stakingWalletInfo["stakedAmount"]) > 0 &&
                bnbBalance > 0 &&
                stakingWalletInfo["withdrawTimestamp"] === 0
            ) {
                setEnableUnStakeBtn(true);
            } else {
                setEnableUnStakeBtn(false);
            }
        }
        if (unStakeCurrentStep === 1) setEnableUnStakeBtn(true);
    }, [acceptTerm, isConnectWallet, bnbBalance, stakingInfo, stakingWalletInfo, unStakeCurrentStep])


    const unStakeBack = () => {
        if (unStakeCurrentStep === 1) {
            return;
        } else {
            setUnStakeCurrentStep(unStakeCurrentStep - 1);
        }
    }

    const unStakeNext = () => {
        if (unStakeCurrentStep === 1) {
            setEnableUnStakeBtn(false);
            setUnStakeCurrentStep(unStakeCurrentStep + 1);
            return;
        }

        if (unStakeCurrentStep === 2 && parseFloat(unStakeAmount) === 0) {
            setEnableUnStakeBtn(false);
            setUnStakeCurrentStep(unStakeCurrentStep + 1);
            return;
        }

        if (unStakeCurrentStep === 4) {
            if (walletUtils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                walletUtils.stakingInitiateWithdrawal({ amount: isMaxAmount ? stakingWalletInfo["stakedAmount"] : unStakeAmount }, data => {
                    // console.log(data);
                    if (data.status === STATUS.STAKING_INITIATE_WITHDRAWAL_SUCCESS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true);
                        setTransactionHash(data.txID);
                        toast.success("Init Unstake Tokens successfully!");   
                        setUnStakeCurrentStep(5);

                    }
                    if (data.status === STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })

                        toast.error("Init Unstake fails");
                    }
                })
            }
        } else {
            if (unStakeCurrentStep === 5) {
                return;
            } else {
                setUnStakeCurrentStep(unStakeCurrentStep + 1);
            }
        }
    }

    const handleInputUnStake = (e) => {
        setIsMaxAmount(false);

        if (helpers.isFloatFormatted(e.target.value, 4)) {
            setUnStakeAmount(e.target.value);

            //check input
            if (BigNumber(e.target.value).lte(BigNumber(stakingWalletInfo["stakedAmount"])) && BigNumber(e.target.value).gt(BigNumber(0))) {
                setEnableUnStakeBtn(true);
            } else {
                setEnableUnStakeBtn(false);
            }
        }
    }

    const handleMaxButtonClick = () => {
        setUnStakeAmount(helpers.formatNumberDownRound(stakingWalletInfo["stakedAmount"], 4));
        setEnableUnStakeBtn(true);
        setIsMaxAmount(true);
    }


    const submitDone = () => {
        setUnStakeAmount('0');
        setIsMaxAmount(true);
        setUnStakeCurrentStep(1);
        setIsSubmitOK(false);
    }


    function setUnStakeStepActive() {
        $('#unStake .bs-stepper-header .step').removeClass('active');
        $('#unStake .bs-stepper-header .line').removeClass('active');
        for (var i = 1; i <= 5; i++) {
            if (i <= unStakeCurrentStep) {
                $('#unStake #unStakeButtonStep' + i).addClass('active');
                $('#unStake #unStakeLineStep' + (i - 1)).addClass('active');
            }
        }
        $('#unStake .bs-stepper-content').hide();
        $('#unStake #UnStakeStep' + unStakeCurrentStep).show();
    }

    return (
        <StakingPortalPage selectedTab={2}>
            <div className="tab-pane fade show active" id="unStake" role="tabpanel" aria-labelledby="unStake-tab">
                <div className="row justify-content-center mb-4">
                    <div className="col-lg-12">
                        <div className="bs-stepper w-100">
                            <div className="bs-stepper-header" role="tablist">
                                {/* your steps here */}
                                <div className="step active" id="unStakeButtonStep1">
                                    <button type="button" className="step-trigger ps-0">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label first">Warning</span>
                                        {/* <span className="bs-stepper-circle ms-0"><i className="mdi mdi-exclamation" /></span> */}
                                    </button>
                                </div>
                                <div className="line" id="unStakeLineStep1" />
                                <div className="step" id="unStakeButtonStep2">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label">Checkpoints</span>
                                        {/* <span className="bs-stepper-circle"><i className="mdi mdi-format-list-checkbox" /></span> */}
                                    </button>
                                </div>
                                <div className="line" id="unStakeLineStep2" />
                                <div className="step" id="unStakeButtonStep3">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label">Amount to Unstake</span>
                                        {/* <span className="bs-stepper-circle"><i className="mdi mdi-currency-usd" /></span> */}
                                    </button>
                                </div>
                                <div className="line" id="unStakeLineStep3" />
                                <div className="step" id="unStakeButtonStep4">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label">Initialize Unstake</span>
                                        {/* <span className="bs-stepper-circle"><i className="mdi mdi-currency-usd" /></span> */}
                                    </button>
                                </div>
                                <div className="line" id="unStakeLineStep4" />
                                <div className="step" id="unStakeButtonStep5">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label last">Confirmation</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="">
                            <div className="p-sidebar-card-body">
                                <div className="bs-stepper-content" id="UnStakeStep1">
                                    <h4 className="mb-4 step-title">Warning</h4>
                                    <div className="step-warning d-flex justify-content-center">
                                        <div className="me-3"><i className="fas fa-exclamation-triangle fa-3x"></i></div>
                                        <div>
                                            <p className="step-warning mb-2">After Unstaking, you must wait 7 days before you can withdraw your $CPO and rewards.</p>
                                            {/* <p className="step-warning">The amount of tokens you Unstake will not count towards your tier level for upcoming <Link target="_blank" to={ROUTES.STACKING} >Projects</Link>.</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="bs-stepper-content" id="UnStakeStep2">
                                    <div className="text-center">
                                        <h4 className="mb-0 step-title">Checkpoints</h4>
                                        <p className="step-desc">The following conditions must be met to proceed:</p>
                                    </div>
                                    <div className="row mt-4 d-flex justify-content-center">
                                        <div className="col-xl-6 col-lg-6 col-md-6 mb-md-0 mb-3">
                                            <div className={`checkpoint ${isConnectWallet ? 'selected' : ''}`}>
                                                <h4>Connected with Metamask</h4>
                                                <p>If not connected, click the "Connect Wallet" button in the top right corner</p>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 mb-md-0 mb-3">
                                            <div className={`checkpoint ${parseFloat(stakingWalletInfo["stakedAmount"]) > 0 ? 'selected' : ''}`}>
                                                <h4>Have an active $CPO stake</h4>
                                                <p>You currently have {helpers.formatNumberDownRound(stakingWalletInfo["stakedAmount"], 2)} $CPO staked</p>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 mb-md-0 mb-3">
                                            <div className={`checkpoint ${bnbBalance > 0 ? 'selected' : ''}`}>
                                                <h4>BNB available in wallet</h4>
                                                <p>
                                                    BNB is required to pay transaction fees on the Binance Smart Chain network.<br />
                                                    BNB Balance: {helpers.formatNumberDownRoundWithExtractMax(bnbBalance, 4)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 mb-md-0 mb-3">
                                            <div className={`checkpoint ${stakingWalletInfo["withdrawTimestamp"] === 0 ? 'selected' : ''}`}>
                                                <h4>Eligible to initiate unstake</h4>
                                                <p>You cannot unstake if you already have an active $CPO unstake/withdrawal request</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <div className="form-check">
                                            <input className="form-check-input float-none me-1"
                                                type="checkbox" defaultValue id="flexCheckDefault"
                                                onChange={() => setAcceptTerm(!acceptTerm)} />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                I have read the&nbsp;
                                                <Link target="_blank" to={ROUTES.TERM_OF_USES}>Terms and Conditions </Link>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="bs-stepper-content" id="UnStakeStep3">
                                    <div className="text-start">
                                        <h4 className="mb-0 step-title">Amount to Unstake</h4>
                                        <p className="step-desc">Please enter the amount of $CPO you want to unstake</p>
                                        <div className="text-start mt-5" style={{ maxWidth: '370px' }}>
                                            <div className="p-form-group mb-1">
                                                <label className="form-label p-main-text">Amount</label>
                                                <div className="p-input-group">
                                                    <input type="number" className="form-control px-0" placeholder={0.0} value={unStakeAmount} onChange={(e) => handleInputUnStake(e)} />
                                                    <Button
                                                        onClick={handleMaxButtonClick}
                                                    >
                                                        MAX
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bs-stepper-content" id="UnStakeStep4">
                                    <div className="text-start">
                                        <h4 className="mb-0 step-title">Initialize Unstake</h4>
                                        <p className="step-desc">In this step, you initiate the unstaking process. After a 7 day waiting period, you will be allowed to withdraw your $CPO</p>
                                    </div>
                                </div>
                                <div className="bs-stepper-content" id="UnStakeStep5">
                                    <h4 className="mb-3 step-title">Confirmation</h4>
                                    <div className="text-center p-3 bg-light" style={{ borderRadius: '15px' }}>
                                        <div className="text-success">
                                            <i className="mdi mdi-shield-check-outline" style={{ fontSize: '70px', lineHeight: 1.3 }} />
                                        </div>
                                        <p className="step-desc">
                                            You have initiated the unstaking process and started the 7 day timer.<br />
                                            If desired, you may check Binance Smart Chain to confirm the transaction.
                                        </p>
                                        <h4><a className="p-address" href={`${BSC_EXPLORER[MODE]}/tx/${transactionHash}`} target="blank">{transactionHash}</a></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`step-buttons ${unStakeCurrentStep === 5 ? 'text-center' : ''}`}>
                    {!isSubmitOK ?
                        <>
                            <button onClick={() => unStakeBack()} type="button"
                                disabled={!enableUnStakeBtn || unStakeCurrentStep === 1}
                                className="btn-cus blue-btn"><i className="mdi mdi-arrow-left me-2" />Previous</button>
                            <button onClick={() => unStakeNext()} type="button"
                                disabled={!enableUnStakeBtn || unStakeCurrentStep === 5}
                                className="btn-cus blue-btn">Next<i className="mdi mdi-arrow-right ms-2" /></button></>
                        :
                        <>
                            <button onClick={() => submitDone()} type="button"
                                className="btn-cus blue-btn">Done</button>
                        </>
                    }
                </div>
            </div>
        </StakingPortalPage>
    );
};

export default UnStakingPage;
