import { get } from "lodash";
import React, { useEffect, useState } from "react";
import $ from 'jquery';
import { useDispatch, useSelector } from "react-redux";
import { ACTION_CONST, STATUS } from "../constants";
import { helpers } from "../utils";
import { getCountDown } from "../utils/helper";
import { toast } from "react-toastify";

const RightBar = (props) => {

  const dispatch = useDispatch();

  const isConnectWallet = useSelector((state) => get(state, "utils.isConnectWallet", false));
  const walletAddress = useSelector((state) => get(state, "utils.walletAddress", ""));
  const walletUtils = useSelector((state) => get(state, "utils.walletUtils", null));
  const [stakedAmount, setStakedAmount] = useState('0');
  const [unstakedAmount, setUnstakedAmount] = useState('0');
  const [rewardAmount, setRewardAmount] = useState('0');
  const [withdrawTimestamp, setWithdrawTimestamp] = useState(0);
  const submitOK = useSelector((state) =>
    get(state, "utils.blocking", false)
  );

  useEffect(() => {
    if (walletUtils && walletAddress) {
      walletUtils.getStakingInfoWallet().then(data => {
        setStakedAmount(data.stakedAmount);
        setUnstakedAmount(data.unstakedAmount);
        setRewardAmount(data.rewardAmount);
        const now = new Date().getTime();
        if (data.withdrawTimestamp > now) {
          setWithdrawTimestamp(data.withdrawTimestamp);
        }
        dispatch({ type: ACTION_CONST.GET_STAKING_WALLET_INFO, data: data })
      });

      //setup interval
      const job = setInterval(() => {
        walletUtils.getStakingInfoWallet().then(data => {
          setStakedAmount(data.stakedAmount);
          setUnstakedAmount(data.unstakedAmount);
          setRewardAmount(data.rewardAmount);
          const now = new Date().getTime();
          if (data.withdrawTimestamp > now && data.withdrawTimestamp !== withdrawTimestamp) {
            setWithdrawTimestamp(data.withdrawTimestamp);
          }
          dispatch({ type: ACTION_CONST.GET_STAKING_WALLET_INFO, data: data })
        })
      }, 10 * 1000);

      dispatch({ type: ACTION_CONST.SET_JOB_GET_STAKING_WALLET_INFO, job });
    }
  }, [walletUtils, walletAddress, submitOK, dispatch, withdrawTimestamp])

  useEffect(() => {
    if (withdrawTimestamp > 0) {
      getCountDown(`${walletAddress}-endtime`, withdrawTimestamp, (job) => {

        dispatch({
          type: ACTION_CONST.SET_JOB_COUNTDOWN_STAKE_TIME,
          data: job
        });

      }, (job) => {
        setWithdrawTimestamp(0);
      });
    }
  }, [dispatch, walletAddress, withdrawTimestamp])

  //handle button withdraw click 
  const handleExcWithDraw = () => {
    if (walletUtils) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      walletUtils.stakingExecuteWithdrawRewards(data => {
        if (data.status === STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_SUCCESS) {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          toast.success("Withdraw stake successfully");
        }
        if (data.status === STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL) {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          toast.error("Withdraw stake fail");
        }
      })
    }
    //to do somrthing
  }

  //stake reward
  const handleExStakeRewards = async () => {
    if (walletUtils) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      walletUtils.stakingRewards(data => {
        if (data.status === STATUS.STAKING_REWARDS_SUCCESS) {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          toast.success("Stake rewards successfully");
        }
        if (data.status === STATUS.STAKING_REWARDS_FAIL) {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          toast.error("Stake rewards fail");
        }
      })
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-4 col-md-6">
          <div className="roadbox roadbox2">
            <div className="roadboxdate"><span>Your Staked</span></div>
            <div className="roadboxtext">
              <div className="roadbox-value">{helpers.formatNumberDownRoundWithExtractMax(stakedAmount, 4)}</div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="roadbox roadbox1">
            <div className="roadboxdate"><span>Your Unstaked</span></div>
            <div className="roadboxtext">
              <div className="roadbox-value">{helpers.formatNumberDownRoundWithExtractMax(unstakedAmount, 4)}</div>
              {parseFloat(unstakedAmount) > 0 && withdrawTimestamp > 0 && (
                <div className="text-dark font-15">
                  Withdrawable in:&nbsp;
                  <b id={`${walletAddress}-endtime`}></b>
                </div>
              )}
              {parseFloat(unstakedAmount) > 0 && withdrawTimestamp === 0 && (<div className="text-dark font-15">Withdrawable Now</div>)}
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="roadbox roadbox2">
            <div className="roadboxdate"><span>Your Rewards</span></div>
            <div className="roadboxtext">
              <div className="roadbox-value">{helpers.formatNumberDownRoundWithExtractMax(rewardAmount, 4)}</div>
              <div className="p-sidebar-card-actions">
                <button className="btn-cus blue-btn"
                  disabled={!isConnectWallet || parseFloat(rewardAmount) === 0 || parseFloat(unstakedAmount) > 0}
                  onClick={() => handleExStakeRewards()}>Stake</button>
                <button className="btn-cus blue-btn" style={{ marginLeft: '-10px' }}
                  disabled={!isConnectWallet || parseFloat(rewardAmount) === 0 || parseFloat(unstakedAmount) > 0}
                  onClick={() => handleExcWithDraw()}>Withdraw</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RightBar;
